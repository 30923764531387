import {
  Box,
  TableContainer,
  Table,
  Tbody,
  Tr,
  Td,
  useInterval,
  Tooltip,
  useBreakpointValue,
  Th,
  Thead,
} from "@chakra-ui/react";
import { HistorySchema, isCanton, localDoctorNames } from "irs-shared";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { DateText } from "./DateText";
import { OuterBox } from "./OuterBox";

function timeGranularity(history: HistorySchema | undefined) {
  const now = Date.now();

  const mostRecent = history?.changes?.at(-1)?.at ?? 0;
  if (mostRecent + 3600 * 1000 > now) {
    // In the first hour after the most recent entry, update every 30 seconds
    return 30 * 1000;
  } else {
    // Afterwards, check every half an hour for updates
    return 1800 * 1000;
  }
}

// Should be returned by server, ideally
export function fullName(mail: string) {
  const match = mail.match(/^(\w+)@(\w+)\.ch$/);
  if (match?.length === 3) {
    const canton = match[2].toUpperCase();
    if (isCanton(canton)) {
      const doctorNames = localDoctorNames(canton);
      const name = match[1].toLowerCase();
      if (name in doctorNames) {
        return `${doctorNames[name as keyof typeof doctorNames]} ${canton}`;
      }
    }
  }
  return mail;
}

export function History(props: { history: HistorySchema | undefined }) {
  const { history } = props;
  const { t } = useTranslation("");
  const [recheck, setRecheck] = useState(0);
  useInterval(() => setRecheck(recheck + 1), timeGranularity(history));
  const narrow = useBreakpointValue({ base: true, sm: false });
  if (history?.changes) {
    return (
      <OuterBox name="history.title" colorScheme="gray" brighter>
        <TableContainer width="100%">
          <Table>
            <Thead>
              <Tr>
                <Th>{t("history.what")}</Th>
                <Th>{t("history.who")}</Th>
                <Th>{t("history.when")}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {history.changes.map((v, i) => (
                <Tr key={`${i}`}>
                  <Td>
                    {t(
                      i === 0
                        ? history!.recovered
                          ? "history.recovered"
                          : "history.created"
                        : "history.updated"
                    )}
                  </Td>
                  <Td>
                    <Tooltip label={narrow ? fullName(v.by) : v.by}>
                      {narrow ? v.by : fullName(v.by)}
                    </Tooltip>
                  </Td>
                  <Td>
                    <DateText millis={v.at} />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
        <Box height={3} />
      </OuterBox>
    );
  } else {
    return null;
  }
}
