import { createContext } from "react";

export type EditingState = {
  editing: boolean;
  editable: boolean;
  setEditing: (nextEditing: boolean) => void;
  setEditable: (nextEditable: boolean) => void;
};
// Better safe than sorry
const defaultEditingState = {
  editing: false,
  editable: false,
  setEditing: (nextEditing: boolean) =>
    console.error(`Default editing state cannot be changed to ${nextEditing}`),
  setEditable: (nextEditable: boolean) =>
    console.error(
      `Default editable state cannot be changed to ${nextEditable}`
    ),
};

export const EditingContext = createContext(defaultEditingState);
EditingContext.displayName = "EditingContext";
