import {
  Box,
  Center,
  chakra,
  keyframes,
  TableContainer,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { createColumnHelper } from "@tanstack/react-table";
import { z } from "zod";
import { diagnosisStatisticsSchema, ExtendedCantons } from "irs-shared";
import { CenteredSpinner } from "../components/CenteredSpinner";
import { OuterBox } from "../components/OuterBox";
import { MaybeTooltip } from "../components/MaybeTooltip";
import { DataTable } from "../components/DataTable";
import { InnerBox } from "../components/InnerBox";
import { CantonGraph } from "../components/CantonGraph";
import { trpc } from "../util/trpc";
import { QueryError } from "../components/QueryError";
import { localStorageKeys } from "../constants";
import { useState } from "react";

type DiagnosesElement = { canton: ExtendedCantons; total: number } & z.infer<
  typeof diagnosisStatisticsSchema
>;

const changeFrames = keyframes`
0% { transform: scale(1.3); color: #718096; font-weight: normal; }
5% { transform: scale(2.5); color: #4040ff; font-weight: bold; }
20% { transform: scale(2.5); }
25% { transform: scale(1.3); }
95% { color: #4040ff; font-weight: bold; }
100% { color: #718096; font-weight: normal; }`;
const changeAnim = `${changeFrames} 20s linear`;

const columnHelper = createColumnHelper<DiagnosesElement>();

function sum(obj: { [k: string]: number[] }): number {
  return Object.values(obj)
    .map((v) => v.length)
    .reduce((prev, curr) => prev + curr);
}

export function Statistics() {
  const [prevStats, setPrevStats] = useState<Record<
    string,
    Record<string, any>
  > | null>(null);
  const context = trpc.useContext();
  const stats = trpc.statistics.reports.useQuery(undefined);
  trpc.statistics.watch.useSubscription(undefined, {
    onData: (data) => {
      setPrevStats(stats.data?.diagnosesByCanton ?? null);
      context.queryClient.setQueryData(["statistics.reports"], data);
    },
  });
  const { t } = useTranslation("");

  function vsum(field: keyof DiagnosesElement) {
    if (!stats.data) {
      return <chakra.span color="gray.500">—</chakra.span>;
    } else {
      if (field === "canton") {
        return t("statistics.sum");
      } else if (field === "total") {
        return <b>{stats.data.total}</b>;
      } else {
        return Object.values(stats.data.diagnosesByCanton)
          .map((o) => o[field].length)
          .reduce((prev, curr) => prev + curr);
      }
    }
  }

  const columns = (
    [
      "canton",
      "anthrax",
      "botulism",
      "hemorrhagic_fever",
      "pest",
      "influenza_a",
      "sars",
      "pox",
      "exceptional",
      "total",
      "canton",
    ] as (keyof DiagnosesElement)[]
  ).map((field, i, fields) =>
    columnHelper.accessor(field, {
      // The duplicate canton <td>s get a different React key
      id: i !== 0 && field === fields[0] ? "dup" : field,
      cell: (info) => {
        let v = info.getValue();
        if (Array.isArray(v)) {
          v = v.length;
        }
        let changed = false;
        if (info.table.options.meta?.prev) {
          const prev =
            info.table.options.meta.prev[info.row.original.canton][
              info.column.id
            ];
          if (Array.isArray(prev)) {
            if (prev.length !== v) {
              changed = true;
            }
          }
        }
        return v === 0 ? (
          <chakra.div
            color="gray.500"
            transformOrigin="center right"
            animation={changed ? changeAnim : undefined}
          >
            —
          </chakra.div>
        ) : field === "total" ? (
          <b>{v}</b>
        ) : v === "??" ? (
          <MaybeTooltip name="statistics.other">
            {t("statistics.other")}
          </MaybeTooltip>
        ) : (
          <chakra.div
            transformOrigin="center right"
            animation={changed ? changeAnim : undefined}
          >
            {v}
          </chakra.div>
        );
      },
      header: () => (
        <MaybeTooltip name={`statistics.${field}`}>
          {t(`statistics.${field}`)}
        </MaybeTooltip>
      ),
      footer: () => vsum(field),
      meta: { isNumeric: field !== "canton" },
    })
  );

  // The empty <Box> below the <DataTable> is to have a better visibility/grip on the horizontal scrollbar
  return (
    <Center padding={3} width="100%">
      <OuterBox name={t("statistics.title")} width="auto" maxWidth="95vw">
        {stats.isLoading ? (
          <CenteredSpinner />
        ) : stats.isError ? (
          <QueryError error={stats.error} />
        ) : stats.data ? (
          <VStack width="100%" spacing={6}>
            <InnerBox name={t("statistics.byCanton")}>
              <CantonGraph data={stats.data} />
            </InnerBox>
            <InnerBox name={t("statistics.byInfection")}>
              <CantonGraph data={stats.data} byInfection />
            </InnerBox>
            <InnerBox name={t("statistics.asTable")}>
              <TableContainer overflowY="auto">
                <DataTable
                  columns={columns}
                  prevData={prevStats ?? undefined}
                  data={
                    Object.entries(stats.data.diagnosesByCanton).map(
                      ([k, v]) => {
                        return { canton: k, ...v, total: sum(v) };
                      }
                    ) as DiagnosesElement[]
                  }
                  persistSortAs={localStorageKeys["STATS_SORT"]}
                  initialSort={[{ id: "total", desc: true }]}
                  size="sm"
                  rowWidth="7em"
                />
                <Box height="1rem" />
              </TableContainer>
            </InnerBox>
          </VStack>
        ) : (
          <Text>Empty data</Text>
        )}
      </OuterBox>
    </Center>
  );
}
