import {
  Box,
  Button,
  Center,
  HStack,
  Spacer,
  Stack,
  Text,
  Textarea,
  useBreakpointValue,
  useToast,
  VStack,
} from "@chakra-ui/react";
import {
  ArrowUpIcon,
  CheckCircleIcon,
  DeleteIcon,
  WarningTwoIcon,
} from "@chakra-ui/icons";
import { z } from "zod";
import { useState } from "react";
import {
  InfectionSchema,
  clone,
  infectionSchema,
  action,
  blankInfection,
  summarize,
} from "irs-shared";
import { Patient } from "../components/Patient";
import { Diagnosis } from "../components/Diagnosis";
import { Exposition } from "../components/Exposition";
import { Measures } from "../components/Measures";
import { OuterBox } from "../components/OuterBox";
import { History } from "../components/History";
import { TFunction, useTranslation } from "react-i18next";
import { useSchemaZorm, dataZorm, subDataZorm } from "../util/dataZorm";
import { CenteredSpinner } from "../components/CenteredSpinner";
import { boringButtonProps, dangerButtonProps, textInputTheme } from "../App";
import { useParams } from "react-router";
import { useHistory } from "react-router";
import { Helmet } from "react-helmet";
import { useReportUndo } from "../hooks/useReportUndo";
import { EditingContext } from "../contexts/EditingContext";
import { useLogin } from "../contexts/LoginContext";
import { LabReports } from "../components/LabReports";
import { trpc } from "../util/trpc";

function infectionSummary(
  ir: InfectionSchema,
  t: TFunction<"", undefined>
): string {
  return summarize("", ir)
    .diagnosis.map((d) => t(`diagnosis.${d}`))
    .join(", ");
}

function autofill(ir0: InfectionSchema, mock: any): InfectionSchema {
  const ir = clone(ir0);
  const yes: z.infer<typeof action> = { choice: "YES" };
  ir.patient.occupation = mock.occupation ?? "?";
  ir.diagnosis.sars = true;
  ir.exposition.kind = { choice: "TRAVEL" };
  ir.exposition.location = mock.infection ?? "?";
  ir.measures.patient.hospital = {
    name: mock.hospital ?? "?",
    phone: "+41 99 888 77 66",
    fax: "+41 99 888 77 66",
  };
  ir.measures.patient.isolated = yes;
  ir.measures.patient.therapy = yes;
  ir.measures.diagnosis.lab = {
    name: mock.lab ?? "?",
    phone: "+41 99 888 77 66",
    fax: "+41 99 888 77 66",
  };
  ir.measures.diagnosis.informed = yes;
  ir.measures.prevention = {
    personnel: {
      taken: yes,
      measures: mock.measures ?? "?",
    },
    contacts: {
      identified: yes,
      informed: yes,
      prophylaxis: yes,
      quarantine: yes,
      aftercare: yes,
    },
    disinfection: yes,
  };
  return ir;
}

type RouteParams = { reportId?: string; recover?: string };

/**
 * The entire infection report
 * @param props
 * @returns
 */
export function InfectionReport(props: {}) {
  const { reportId, recover } = useParams<RouteParams>();
  const history = useHistory();
  const [reportUndo, setReportUndo, deleteReportUndo] = useReportUndo();
  const toast = useToast();
  const [login] = useLogin();
  const { t } = useTranslation("");
  // `/new[/recover]` starts in read/write mode
  const [editing, setEditing] = useState(reportId === undefined);
  const [ir, setIr] = useState<InfectionSchema | null>(
    reportId ? null : recover ? reportUndo ?? blankInfection : blankInfection
  );
  // Query only enabled for /report/:id;
  // only refetch as long as the user hasn't started editing
  const reportQuery = trpc.report.get.useQuery(reportId ?? "", {
    enabled: !!reportId,
    refetchOnWindowFocus: !editing,
    refetchOnReconnect: !editing,
    onSuccess: setIr,
  });
  // Only enabled for /new (not /new/recover);
  // refetching has positive effects when there was an error initially;
  // will not have any effect when patient.name already set (except
  // slight overhead)
  const mockNameQuery = trpc.mock.name.useQuery(login?.canton, {
    enabled: !reportId && !recover,
    staleTime: 500,
    cacheTime: 500,
  });
  const createMutation = trpc.report.create.useMutation();
  const updateMutation = trpc.report.update.useMutation();
  const deleteMutation = trpc.report.delete.useMutation();
  const notTiny = useBreakpointValue({ base: false, md: true });
  const zo = useSchemaZorm("ir", infectionSchema, {
    onValidSubmit(e) {
      const input: InfectionSchema & { _id: string } = {
        ...e.data,
        attachments: ir?.attachments,
        history: recover && reportUndo ? { recovered: true } : undefined,
        // "" only for using the same type for create and update; will be stripped at submission
        _id: reportId ?? "",
      };
      function onSuccess() {
        toast({
          status: "success",
          title: t(
            !!reportId ? "report.create.submitted" : "report.update.submitted"
          ),
          description: t(
            !!reportId
              ? "report.create.submitted_body"
              : "report.update.submitted_body"
          ),
        });
        deleteReportUndo();
        history.push("/");
      }
      e.preventDefault();
      if (reportId === undefined) {
        createMutation.mutate(input, { onSuccess });
      } else {
        updateMutation.mutate(input, { onSuccess });
      }
    },
  });
  const disabled = zo.validation?.success === false;

  // The enabled query, if any, is loading: Show spinner
  if (
    (reportQuery.isLoading && reportQuery.isFetching) ||
    (mockNameQuery.isLoading && mockNameQuery.isFetching)
  ) {
    return <CenteredSpinner />;
  }

  if (mockNameQuery.data && ir?.patient.name === "") {
    const newIr = clone(ir);
    newIr.patient.name = `${mockNameQuery.data.first} ${mockNameQuery.data.last}`;
    newIr.patient.domicil.canton = mockNameQuery.data.canton;
    newIr.patient.domicil.country = "CH";
    newIr.patient.domicil.address = `${mockNameQuery.data.plz} ${mockNameQuery.data.townNoCanton}`;
    newIr.patient.dateOfBirth = "01.01.1970";
    newIr.patient.sex = mockNameQuery.data.gender === "f" ? "FEMALE" : "MALE";
    newIr.patient.nationality = "CH";
    newIr.patient.phoneNumber = "+41 99 888 77 66";
    newIr.patient.face = mockNameQuery.data.face;
    setIr(newIr);
  }

  // As soon as reportQuery is ready, it is being rendered; even if
  // the data has not yet been setIr()ed. So we work around this…
  // (As rendering "undefined" as the MultipleChoiceWithRefinement checkbox
  // will cause confusion there; and also there will be visible rerendering)
  const dz = dataZorm(
    zo,
    (ir ?? reportQuery.data ?? blankInfection) as InfectionSchema
  );

  function validationStatus() {
    if (
      zo.validation &&
      (zo.validation as any).error &&
      (zo.validation as any).error.issues
    ) {
      return (
        <Text color="red">
          <WarningTwoIcon />
          {` ${t("errors.counter", {
            count: (zo.validation as any).error.issues.length,
          })} `}
          <ArrowUpIcon />
        </Text>
      );
    } else if (zo.validation?.success) {
      return (
        <Text color="green">
          <CheckCircleIcon /> {t("errors.submit")}
        </Text>
      );
    } else {
      return <Text>&nbsp;</Text>;
    }
  }

  function validationButtons() {
    return (
      <Stack
        direction={{ base: "column", md: "row" }}
        width="100%"
        marginBottom={6}
      >
        {editing ? (
          <Button
            disabled={disabled}
            type="submit"
            isLoading={createMutation.isLoading || updateMutation.isLoading}
            border="solid 3px"
          >
            <Text marginTop={1}>
              {t(
                reportId === undefined
                  ? recover && reportUndo
                    ? ["resubmit", "submit"]
                    : "submit"
                  : "update"
              )}
            </Text>
          </Button>
        ) : null}
        {notTiny ? <Spacer /> : <Box height={3} />}
        <Button
          {...boringButtonProps}
          border="solid 2px"
          onClick={history.goBack}
        >
          <Text marginTop={1}>
            {t(login === null || !editing ? ["leave", "cancel"] : "cancel")}
          </Text>
        </Button>
        {reportId == null || login === null ? null : (
          <Button
            isLoading={deleteMutation.isLoading}
            {...dangerButtonProps}
            rightIcon={<DeleteIcon />}
            onClick={() => {
              deleteMutation.mutate(reportId, {
                onSuccess: () => {
                  toast({
                    status: "success",
                    title: t("report.delete.submitted"),
                    description: t("report.delete.submitted_body"),
                  });
                  setReportUndo(ir!);
                  history.push("/");
                },
              });
            }}
          >
            <Text marginTop={1}>{t("delete")}</Text>
          </Button>
        )}
      </Stack>
    );
  }

  return (
    <Center>
      <Helmet>
        <title>
          {"IRS: "}
          {reportId === undefined
            ? t("navbar.new")
            : `${dz.data.patient.name}, ${dz.data.patient.domicil.address} (${
                dz.data.patient.domicil.canton ??
                dz.data.patient.domicil.country
              }): ${infectionSummary(dz.data, t)}`}
        </title>
      </Helmet>
      <VStack
        width="min(100vw, 40rem)"
        paddingX={2}
        fontSize={{ base: "md", md: "xl" }}
      >
        <EditingContext.Provider
          value={{
            editable: !!login,
            setEditable: () => {},
            editing,
            setEditing,
          }}
        >
          <form ref={zo.ref} style={{ width: "100%" }}>
            <HStack justifyContent="right">
              <Button
                {...dangerButtonProps}
                marginTop={6}
                marginBottom={3}
                onClick={() => {
                  setIr(autofill(ir!, t("mock", { returnObjects: true })));
                }}
              >
                <Text marginBottom={-1} fontSize={{ base: "lg", md: "xl" }}>
                  {t("autofill")}
                </Text>
              </Button>
            </HStack>
            <Patient dz={subDataZorm(dz, "patient")} />
            <Diagnosis dz={subDataZorm(dz, "diagnosis")} />
            <Exposition dz={subDataZorm(dz, "exposition")} />
            <Measures dz={subDataZorm(dz, "measures")} />
            <OuterBox name="comments">
              {!!login ? (
                <Textarea
                  isReadOnly={!editing}
                  width="100%"
                  name={zo.fields.comments()}
                  defaultValue={ir?.comments ?? ""}
                  {...textInputTheme}
                />
              ) : (
                <Text
                  paddingY={1}
                  minHeight="5rem"
                  maxHeight="15rem"
                  overflowY="scroll"
                  paddingLeft={3}
                  color="brand.900"
                  backgroundColor="brand.50"
                  width="100%"
                  whiteSpace="pre-wrap"
                >
                  {ir?.comments ?? ""}
                </Text>
              )}
            </OuterBox>
            <LabReports dz={dz} setIr={setIr} reportId={reportId} />
            {validationStatus()}
            {validationButtons()}
          </form>
        </EditingContext.Provider>
        <History history={dz.data.history} />
        <Box width="100%" height="5em" />
      </VStack>
    </Center>
  );
}
