import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Center, Heading, Link, Text, Box, VStack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { LoginForm } from "../components/LoginForm";
import { ReportList } from "../components/ReportList";
import { ReportUndo } from "../components/ReportUndo";

export function Landing() {
  const { t } = useTranslation("");

  return (
    <VStack width="100%" margin={0}>
      <Box padding="2em" backgroundColor="brand.50" width="100%" fontSize="xl">
        <Center textAlign="center">
          <VStack width="100%">
            <Heading>{t("welcome")}</Heading>
            <Text color="red.800">{t("warning")}</Text>
            <Text>{t("demonstration")}</Text>
            <Text>
              <Link href="https://trifence.ch">
                {t("more")} <ExternalLinkIcon />
              </Link>
            </Text>
          </VStack>
        </Center>
      </Box>
      <Center>
        <VStack
          marginTop="2rem"
          fontSize="2xl"
          width="100%"
          minHeight="80vh"
          height="100%"
          spacing={6}
        >
          <ReportList />
          <ReportUndo />
          <LoginForm />
          <Text width="min(100vw, 40rem)" paddingX={2} textAlign="center">
            {t("authentication")}
          </Text>
        </VStack>
      </Center>
    </VStack>
  );
}
