import {
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  StackDirection,
  HStack,
  Text,
} from "@chakra-ui/react";
import { ReactNode, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { radioTheme } from "../App";
import { EditingContext } from "../contexts/EditingContext";
import {
  DataZorm,
  disrobe,
  OptionalData,
  stringDataZorm,
} from "../util/dataZorm";
import { HelpText } from "./HelpText";
import { MaybeTooltip } from "./MaybeTooltip";
import { TextInput } from "./TextInput";

/**
 * Conditional <HStack> component
 * @param props
 * @returns
 */
function MaybeHStack(props: { very?: boolean; children: ReactNode }) {
  const { very, children } = props;
  if (very) {
    return (
      <HStack
        justify="space-between"
        width="100%"
        marginBottom={-2}
        alignItems="center"
      >
        {children}
      </HStack>
    );
  } else {
    return <>{children}</>;
  }
}

type ChoiceUnion = z.ZodDiscriminatedUnion<
  "choice",
  string,
  z.ZodDiscriminatedUnionOption<"choice", string>
>;

/**
 * A versatile radio button choice
 * @param props
 * @returns
 */
export function RadioChoices(props: {
  dz: OptionalData<DataZorm<ChoiceUnion>> | DataZorm<z.ZodEffects<ChoiceUnion>>;
  textName?: string;
  direction?: StackDirection;
  hideLabel?: boolean;
  children?: ReactNode;
  selectedBold?: boolean;
  very?: boolean; // To be used for "very compact" only
  compact?: boolean;
  colorScheme?: string;
  onChange?: (value: string) => void;
}) {
  const {
    dz,
    textName,
    direction = "row",
    hideLabel,
    very,
    compact,
    onChange,
    colorScheme = "brand",
  } = props;
  const { t } = useTranslation("");
  const { editing } = useContext(EditingContext);
  const inner0 = disrobe(dz.schema);
  const inner = inner0 as z.ZodDiscriminatedUnion<
    string,
    string,
    z.ZodDiscriminatedUnionOption<string, string>
  >;
  const choices = inner.validDiscriminatorValues;
  const fullName = (dz.fields as any).choice();
  const data = dz.data ?? { selected: false, choice: "" };
  const value: string = data.choice ?? "";
  const [chosen, setChosen] = useState(value);
  if (value !== "" && value !== chosen) {
    setChosen(value);
  }

  return (
    <FormControl as="fieldset">
      <MaybeHStack very={very}>
        {hideLabel ? null : (
          <FormLabel as="legend" fontSize={{ base: "xl", md: "2xl" }}>
            {t(fullName)}{" "}
          </FormLabel>
        )}
        <RadioGroup
          isDisabled={!editing}
          name={(dz.fields as any).choice()}
          defaultValue={chosen}
          key={`${fullName}_${chosen}`}
          onChange={(nextValue) => {
            onChange?.(nextValue);
          }}
        >
          <Stack
            direction={direction}
            spacing={
              direction === "row"
                ? { base: "12px", md: "24px" }
                : compact
                ? "0"
                : { base: "6px", md: "12px" }
            }
            width="100%"
            justify={direction === "row" ? "right" : "left"}
            marginTop={direction === "row" ? -2 : 0}
          >
            {choices.map((v) => {
              if (v === "") {
                return <Radio key={v} value={v} hidden />;
              } else {
                if (v === "OTHER") {
                  return (
                    <HStack
                      key="OTHER"
                      marginTop={-1}
                      paddingBottom={2}
                      alignItems="bottom"
                    >
                      <Radio
                        width="auto"
                        key={`${v}_${chosen}`}
                        value={v}
                        defaultChecked={v === chosen}
                        {...radioTheme}
                      />
                      {textName !== undefined ? (
                        <TextInput
                          {...stringDataZorm(dz as any, textName)}
                          key={textName}
                          hideLabel
                          paddingLeft={2}
                          paddingBottom={0}
                          onClick={() => {
                            setChosen("OTHER");
                          }}
                        />
                      ) : null}
                    </HStack>
                  );
                } else {
                  return (
                    <Radio
                      width="auto"
                      key={`${v}_${chosen}`}
                      value={v}
                      defaultChecked={v === chosen}
                      {...radioTheme}
                    >
                      <MaybeTooltip name={v}>
                        <Text
                          paddingLeft={1}
                          paddingY="0"
                          textColor="gray.700"
                          fontSize={{ base: "xl", md: "2xl" }}
                        >
                          {t(v)}
                        </Text>
                      </MaybeTooltip>
                    </Radio>
                  );
                }
              }
            })}
          </Stack>
        </RadioGroup>
      </MaybeHStack>
      <HelpText name={fullName} colorScheme={colorScheme} />
    </FormControl>
  );
}
