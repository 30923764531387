import { infectionSchemaExposition } from "irs-shared";
import { TextInput } from "./TextInput";
import { MultipleChoiceWithRefinement } from "./MultipleChoiceWithRefinement";
import { OuterBox } from "./OuterBox";
import { DataZorm, stringDataZorm, subDataZorm } from "../util/dataZorm";

/**
 * The `exposition` section of the infectionSchema
 * @param props
 * @returns
 */
export function Exposition(props: {
  dz: DataZorm<typeof infectionSchemaExposition>;
}) {
  const { dz } = props;

  return (
    <OuterBox name="exposition.title">
      <MultipleChoiceWithRefinement
        dz={subDataZorm(dz, "kind")}
        textName="other"
        alwaysActive
      />
      <TextInput {...stringDataZorm(dz, "location")} paddingLeft={6} />
    </OuterBox>
  );
}
