"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.statisticsSchema = exports.diagnosesByCantonSchema = exports.diagnosisStatisticsSchema = void 0;
const zod_1 = require("zod");
const cantons_1 = require("./cantons");
const infection_1 = require("./infection");
exports.diagnosisStatisticsSchema = zod_1.z.object(Object.fromEntries(Object.keys(infection_1.infectionSchemaDiagnosisPlain.keyof().enum).map((diag) => [
    diag,
    zod_1.z.array(zod_1.z.number()),
])));
exports.diagnosesByCantonSchema = zod_1.z.object(Object.fromEntries(cantons_1.extendedCantons.map((c) => [c, exports.diagnosisStatisticsSchema])));
exports.statisticsSchema = zod_1.z.object({
    total: zod_1.z.number(),
    minMillis: zod_1.z.number(),
    maxMillis: zod_1.z.number(),
    diagnosesByCanton: exports.diagnosesByCantonSchema,
});
