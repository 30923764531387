import { SVGProps } from "react";

/**
 * The logo with the (optional) canton name
 * @param props `canton` and `width`
 * @returns component
 */
export function CantonLogo(props: {
  canton?: string;
  width: SVGProps<SVGElement>["width"];
}) {
  const { canton, width } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={width}
      viewBox="0 0 280 144"
    >
      <defs>
        <style>
          {`@font-face { font-family: "Saira"; src: url("/Saira.subset.woff2") format("woff2"); font-stretch: 50% 100%; font-weight: 100 900; }`}
        </style>
        <linearGradient id="A" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#fff" />
          <stop offset="0.5" stopColor="#dfd" />
          <stop offset="1" stopColor="#bdb" />
        </linearGradient>
        <linearGradient xlinkHref="#A" id="B" x1="0" y1="60" x2="0" y2="120" />
        <path
          id="D"
          d="M152 1v76a63 63 0 0 0 0 1 63 63 0 1 0 126 0 63 63 0 0 0 0-1V1z"
        />
      </defs>
      <use xlinkHref="#D" fill="url(#B)" stroke="#000" strokeWidth="3" />
      <path
        d="M156 97l32-22 21 11 19-26 22 8 21-30"
        fill="none"
        stroke="#393"
        strokeWidth="5"
      />
      <use
        xlinkHref="#D"
        x="-150"
        fill="url(#B)"
        stroke="#000"
        strokeWidth="3"
      />
      <g stroke="#393" fill="#fff" strokeWidth="1.5">
        <circle cx="175" cy="22" r="3.5" />
        <circle cx="195" cy="22" r="3.5" />
        <circle cx="215" cy="22" r="3.5" />
        <circle cx="235" cy="22" r="3.5" />
        <circle cx="255" cy="22" r="3.5" />
        <circle cx="175" cy="42" r="3.5" />
        <circle cx="195" cy="42" r="3.5" />
        <circle cx="215" cy="42" r="3.5" />
        <circle cx="235" cy="42" r="3.5" />
        <circle cx="255" cy="42" r="3.5" />
        <circle cx="195" cy="62" r="3.5" />
        <circle cx="175" cy="62" r="3.5" />
        <circle cx="235" cy="82" r="3.5" />
        <circle cx="255" cy="82" r="3.5" />
        <circle cx="175" cy="102" r="3.5" />
        <circle cx="195" cy="102" r="3.5" />
        <circle cx="215" cy="102" r="3.5" />
        <circle cx="235" cy="102" r="3.5" />
        <circle cx="255" cy="102" r="3.5" />
      </g>
      <text
        x="66"
        y="104"
        fontSize="113"
        fontFamily="Saira"
        fontWeight={canton?.includes("W") ? 900 : 700}
        fontStretch={
          canton?.includes("W") ? "50%" : canton?.includes("I") ? "90%" : "68%"
        }
        fill="#060"
        textAnchor="middle"
        textDecoration="none"
      >
        {canton}
      </text>
    </svg>
  );
}
