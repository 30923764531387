import { DeleteIcon } from "@chakra-ui/icons";
import {
  Button,
  Center,
  Heading,
  HStack,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Link as RouteLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { dangerButtonProps } from "../App";
import { useReportUndo } from "../hooks/useReportUndo";

export function ReportUndo() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [reportUndo, _, deleteReportUndo] = useReportUndo();
  const { t } = useTranslation("");

  if (reportUndo) {
    return (
      <Center
        width="min(100vw, 40rem)"
        paddingX={2}
        minHeight="10rem"
        textAlign="center"
      >
        <VStack
          width="100%"
          rounded="xl"
          bgColor="gray.300"
          paddingY="2rem"
          spacing={6}
        >
          <Heading>{t("undo.title")}</Heading>
          <Button as={RouteLink} to="/new/recover" border="2px" minWidth="80%">
            <Text>{t("undo.recover", { name: reportUndo.patient.name })}</Text>
          </Button>
          <HStack minWidth="80%" paddingTop={6}>
            <Spacer />
            <Button
              size="sm"
              border="2px"
              {...dangerButtonProps}
              rightIcon={<DeleteIcon />}
              onClick={deleteReportUndo}
            >
              {t("undo.empty")}
            </Button>
          </HStack>
        </VStack>
      </Center>
    );
  } else {
    return null;
  }
}
