"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.requireMultipleChoice1 = exports.actionMaybe = exports.action = exports.removeIfEmpty = exports.unselectEnumWithOther = exports.writeIn = exports.basicSelectableWriteIn = exports.numberInput = exports.booleanCheckboxTrueOrUndefined = exports.booleanCheckbox = void 0;
const zod_1 = require("zod");
const constants_1 = require("./constants");
//
// Booleans that work with <Checkbox>
//
// Chained transforms (z.ZodEffect<z.ZodEffect<X>>) are not (yet) supported by DataZorm, therefore we need to flatten it at creation time. (Reason: Generalizing results in TypeScript complaining about possible infinite depth)
/**
 * Buildung block for a boolean which can be fed from a checkbox ("1" | undefined) and a (stored) boolean
 */
const booleanCheckboxNoTransform = zod_1.z
    .union([zod_1.z.boolean(), zod_1.z.string().max(constants_1.STRING_MAX)])
    .optional();
/**
 * A boolean which can be fed from a checkbox ("1" | undefined) and a (stored) boolean. Will return `true | false`.
 */
exports.booleanCheckbox = booleanCheckboxNoTransform.transform(Boolean);
/**
 * A boolean which can be fed from a checkbox ("1" | undefined) and a (stored) boolean | undefined and will return `true | undefined`
 */
exports.booleanCheckboxTrueOrUndefined = booleanCheckboxNoTransform.transform(removeIfNotTrue);
//
// Numbers which can be stored in strings (=input fields)
//
exports.numberInput = zod_1.z.union([zod_1.z.number(), zod_1.z.string()]).transform(Number);
/**
 * Normalizes the input to the minimal version, including `undefined`, if it is not `selected`.
 * @param data Input structure
 * @param ctx Zorm context
 * @returns Minimal version of the input data
 */
function selectableWriteInCleaner(data, ctx) {
    if (!(data === null || data === void 0 ? void 0 : data.selected)) {
        return undefined;
    }
    else if (data.choice === "") {
        ctx.addIssue({
            code: zod_1.z.ZodIssueCode.custom,
            message: "Missing choice",
        });
        return data;
    }
    else {
        if (data.choice === "OTHER") {
            if (data.other) {
                return data; //{ selected: true, choice: val.choice, other: val.other };
            }
            else {
                ctx.addIssue({
                    code: zod_1.z.ZodIssueCode.too_small,
                    minimum: 1,
                    type: "string",
                    inclusive: true,
                    message: "Write-in required",
                });
                return data;
            }
        }
        else {
            return data; //{ selected: true, choice: val.choice };
        }
    }
}
/**
 * Normalizes the input to the minimal version.
 * @param data Input structure
 * @param ctx Zorm context
 * @returns Minimal version of the input data
 */
function writeInCleaner(data, ctx) {
    if (data.choice === "") {
        ctx.addIssue({
            code: zod_1.z.ZodIssueCode.custom,
            message: "Missing choice",
        });
    }
    else {
        if (data.choice === "OTHER") {
            if (data.other === "") {
                ctx.addIssue({
                    code: zod_1.z.ZodIssueCode.custom,
                    message: "Write-in required",
                });
            }
            else {
                return { choice: data.choice, other: data.other };
            }
        }
        else {
            return { choice: data.choice };
        }
    }
    return data;
}
/*
  * According to https://github.com/microsoft/TypeScript/issues/29841,
  * this should maintain the arity of the array when going through `.map`.
  * I see no difference, though (still can't remove the
  * `... as Array1Plus<...` guards)
 interface Array<T> {
   map<TThis extends Array<T>, U>(
     this: TThis,
     fn: (v: T) => U
   ): { [K in keyof TThis]: U };
 }
 */
/**
 * Creates a `SelectableWriteIn` with the given options, "OTHER" and ""
 * @param options The options
 * @returns ZodOptional SelectableWriteIn
 */
function basicSelectableWriteIn(options) {
    // Typescript compiler does not know that
    // - `map()` preserves the number of elements and
    // - `z.object()` here is compatible with `ZodDiscriminatedUnion`.
    //
    // So, just for the compiler, we have to
    // - assign it in `map()` to a temporary variable (using
    //   `as` instead would skip type checking)
    // - perform the `hasAtLeastTwoElements()` check.
    let optionTypes = options.map((opt) => {
        const ret = zod_1.z.object({
            selected: exports.booleanCheckbox,
            choice: zod_1.z.literal(opt),
        });
        return ret;
    });
    const otherOption = zod_1.z.object({
        selected: exports.booleanCheckbox,
        choice: zod_1.z.literal("OTHER"),
        other: zod_1.z.string().max(constants_1.STRING_MAX),
    });
    const missingOption = zod_1.z.object({
        selected: exports.booleanCheckbox,
        choice: zod_1.z.literal(""),
    });
    optionTypes.push(otherOption, missingOption);
    return zod_1.z.discriminatedUnion("choice", optionTypes).optional();
}
exports.basicSelectableWriteIn = basicSelectableWriteIn;
/**
 * Creates a `SelectableWriteIn` with the given options, "OTHER" and "".
 * However, the "" value option is only valid for the type, not in the data.
 * @param options The options
 * @returns ZodOptional SelectableWriteIn
 */
function writeIn(options) {
    return basicSelectableWriteIn(options).transform(selectableWriteInCleaner);
}
exports.writeIn = writeIn;
function unselectEnumWithOther(options) {
    // Typescript compiler does not know that
    // - `map()` preserves the number of elements and
    // - `z.object()` here is compatible with `ZodDiscriminatedUnion`.
    //
    // So, just for the compiler, we have to
    // - assign it in `map()` to a temporary variable (using
    //   `as` instead would skip type checking)
    // - perform the `hasAtLeastTwoElements()` check.
    let optionTypes = options.map((opt) => {
        const ret = zod_1.z.object({
            choice: zod_1.z.literal(opt),
        });
        return ret;
    });
    const otherOption = zod_1.z.object({
        choice: zod_1.z.literal("OTHER"),
        other: zod_1.z.string().max(constants_1.STRING_MAX),
    });
    const missingOption = zod_1.z.object({
        choice: zod_1.z.literal(""),
    });
    optionTypes.push(otherOption, missingOption);
    return zod_1.z.discriminatedUnion("choice", optionTypes).transform(writeInCleaner);
}
exports.unselectEnumWithOther = unselectEnumWithOther;
/**
 * Boolean which is only present if needed (i.e., true)
 * @param data Truth value (string | boolean | undefined)
 * @returns `true | undefined`
 */
function removeIfNotTrue(data) {
    return Boolean(data) ? true : undefined;
}
/**
 * String which is only present if needed (i.e., non-empty)
 * @param data String
 * @returns Non-empty string or undefined
 */
function removeIfEmpty(data) {
    return data === "" ? undefined : data;
}
exports.removeIfEmpty = removeIfEmpty;
//
// Action to be taken: String-based truth value with a possible responsible for the action
//
/**
 * Minimized the action structure
 * @param data Data from the form
 * @param ctx Zorm context (for errors)
 * @returns Minimized data
 */
function actionCleaner(data, ctx) {
    if (data.choice === "") {
        ctx.addIssue({
            code: zod_1.z.ZodIssueCode.custom,
            message: "Missing choice",
        });
        return data;
    }
    else if (data.choice === "YES") {
        return { choice: "YES" };
    }
    else {
        if (data.todoFor === undefined) {
            ctx.addIssue({
                code: zod_1.z.ZodIssueCode.custom,
                path: ["todoFor"],
                message: "Missing choice",
            });
            return data;
        }
        else {
            if (data.todoFor.choice === "") {
                ctx.addIssue({
                    code: zod_1.z.ZodIssueCode.custom,
                    path: ["todoFor"],
                    message: "Missing choice",
                });
                return data;
            }
            else {
                if (data.todoFor.choice === "OTHER") {
                    if (data.todoFor.other === "") {
                        ctx.addIssue({
                            code: zod_1.z.ZodIssueCode.custom,
                            path: ["todoFor"],
                            message: "Write-in required",
                        });
                        return data;
                    }
                    else {
                        return {
                            choice: data.choice,
                            todoFor: {
                                choice: data.todoFor.choice,
                                other: data.todoFor.other,
                            },
                        };
                    }
                }
                return {
                    choice: data.choice,
                    todoFor: { choice: data.todoFor.choice },
                };
            }
        }
    }
}
/**
 * "YES" | "NO" choice; if anything except "YES" (i.e., completed)
 * is chosen, also includes a `todoFor` `writeIn`.
 */
exports.action = zod_1.z
    .discriminatedUnion("choice", [
    zod_1.z.object({ choice: zod_1.z.literal("") }),
    zod_1.z.object({ choice: zod_1.z.literal("YES") }),
    zod_1.z.object({
        choice: zod_1.z.literal("NO"),
        todoFor: basicSelectableWriteIn(["CANTONAL_SURGEON", "HOSPITAL"]),
    }),
])
    .transform(actionCleaner);
/**
 * "YES" | "NO" | "MAYBE" choice; if anything except "YES" (i.e., completed)
 * is chosen, also includes a `todoFor` `writeIn`.
 */
exports.actionMaybe = zod_1.z
    .discriminatedUnion("choice", [
    zod_1.z.object({ choice: zod_1.z.literal("") }),
    zod_1.z.object({ choice: zod_1.z.literal("YES") }),
    zod_1.z.object({
        choice: zod_1.z.literal("NO"),
        todoFor: basicSelectableWriteIn(["CANTONAL_SURGEON", "HOSPITAL"]),
    }),
    zod_1.z.object({
        choice: zod_1.z.literal("MAYBE"),
        todoFor: basicSelectableWriteIn(["CANTONAL_SURGEON", "HOSPITAL"]),
    }),
])
    .transform(actionCleaner);
//
// Minimum selected items
//
/**
 * Returns whether there is the given number of (non-undefined) items available
 * @param data Input data
 * @param min Required number of items
 * @returns Whether there are enough items
 */
function requireMultipleChoiceMin(data, min) {
    const present = Object.entries(data).filter(([k, v]) => v != undefined);
    return present.length >= min;
}
/**
 * Returns whether there is at least one (non-undefined) items available
 * @param data Input data
 * @returns Whether there are enough items
 */
function requireMultipleChoice1(data) {
    return requireMultipleChoiceMin(data, 1);
}
exports.requireMultipleChoice1 = requireMultipleChoice1;
