import {
  useDisclosure,
  Box,
  VStack,
  Checkbox,
  Heading,
  Collapse,
} from "@chakra-ui/react";
import { SelectableWriteIn } from "irs-shared";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { checkboxTheme } from "../App";
import { EditingContext } from "../contexts/EditingContext";
import { DataZorm, OptionalData } from "../util/dataZorm";
import { ErrorText } from "./ErrorText";
import { HelpText } from "./HelpText";
import { MaybeTooltip } from "./MaybeTooltip";
import { RadioChoices } from "./RadioChoices";

/**
 * A multiple-choice item with a write-in field for "OTHER"
 * @param props
 * @returns
 */
export function MultipleChoiceWithRefinement<K extends string>(props: {
  dz: OptionalData<DataZorm<SelectableWriteIn<string>>>;
  textName: K;
  alwaysActive?: boolean;
  colorScheme?: string;
}) {
  const { dz, textName, alwaysActive, colorScheme = "brand" } = props;
  const { isOpen, onToggle } = useDisclosure({
    defaultIsOpen: dz.data?.selected ?? false,
  });
  const { t } = useTranslation("");
  const { editing } = useContext(EditingContext);
  const fullName = (dz.fields as any).choice();
  const name = fullName.replace(/\.choice$/, "");

  return (
    <Box backgroundColor="brand.300" width="100%" paddingTop={3} rounded="xl">
      <VStack width="100%" paddingX={3} justifyContent="left">
        {alwaysActive ? (
          <Heading size={{ base: "md", md: "lg" }} marginRight="auto">
            <MaybeTooltip name={name}>{t(name)}</MaybeTooltip>
          </Heading>
        ) : (
          <>
            <Checkbox
              key={`${dz.fields.selected()}_${dz.data?.selected}_${isOpen}`}
              isDisabled={!editing}
              name={dz.fields.selected()}
              value="1"
              defaultChecked={isOpen ?? false}
              onChange={onToggle}
              justifySelf="left"
              width="100%"
              {...checkboxTheme}
            >
              <Heading size={{ base: "md", md: "lg" }}>
                <MaybeTooltip name={name}>{t(name)}</MaybeTooltip>
              </Heading>
            </Checkbox>
            <ErrorText error={dz.errors.selected()} />
          </>
        )}
        <HelpText name={name} colorScheme={colorScheme} />
        <ErrorText error={dz.errors.choice()} />
        <Box width="100%" paddingLeft={3}>
          <Collapse in={!!alwaysActive || isOpen} animateOpacity>
            <RadioChoices
              dz={dz as any}
              textName={textName}
              direction="column"
              hideLabel
            />
            <ErrorText error={dz.errors()} />
          </Collapse>
        </Box>
      </VStack>
    </Box>
  );
}
