import { infectionSchemaPatient } from "irs-shared";
import { TextInput } from "./TextInput";
import { OuterBox } from "./OuterBox";
import { InnerBox } from "./InnerBox";
import { SingleLineRadioChoice } from "./SingleLineRadioChoice";
import {
  DataZorm,
  enumDataZorm,
  stringDataZorm,
  subDataZorm,
} from "../util/dataZorm";
import { Input } from "@chakra-ui/react";
import { apiUrl } from "../constants";

/**
 * The `patient` section of the infectionSchema
 * @param props
 * @returns
 */
export function Patient(props: {
  dz: DataZorm<typeof infectionSchemaPatient>;
}) {
  const { dz } = props;
  const domicilZorm = subDataZorm(dz, "domicil");
  return (
    <OuterBox name="patient.title">
      <InnerBox
        name="patient.personals"
        image={dz.data.face ? `${apiUrl}/face/${dz.data.face}` : undefined}
      >
        <Input type="hidden" name={dz.fields.face()} value={dz.data.face} />
        <TextInput {...stringDataZorm(dz, "name")} />
        <SingleLineRadioChoice {...enumDataZorm(dz, "sex")} />
        <TextInput {...stringDataZorm(dz, "dateOfBirth")} />
        <TextInput {...stringDataZorm(dz, "occupation")} />
        <TextInput {...stringDataZorm(dz, "nationality")} />
        <TextInput {...stringDataZorm(dz, "phoneNumber")} />
      </InnerBox>
      <InnerBox name="patient.domicil.title">
        <TextInput {...stringDataZorm(domicilZorm, "address")} />
        <TextInput {...stringDataZorm(domicilZorm, "canton")} />
        <TextInput {...stringDataZorm(domicilZorm, "country")} />
      </InnerBox>
    </OuterBox>
  );
}
