import {
  Button,
  Link,
  Text,
  useDisclosure,
  useColorModeValue,
  useBreakpointValue,
  ButtonProps,
} from "@chakra-ui/react";
import { GrEdit, GrHelp, GrList } from "react-icons/gr";
import { Link as RouteLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LanguageSwitcher } from "./LanguageSwitcher";
import { NavigationBar } from "./NavigationBar";
import { CantonLogo } from "./CantonLogo";
import { useLogin } from "../contexts/LoginContext";

export function Header() {
  const { t } = useTranslation("irs");
  const { isOpen, onToggle, onClose } = useDisclosure();

  const boxShadow = useColorModeValue("md", "none");
  const logoWidth = useBreakpointValue({ base: "50px", sm: "80px" });
  const [login] = useLogin();

  const regularButtonProps: ButtonProps = {
    // "borderWidth" is ignored
    border: "solid 2px",
    borderColor: "brand.800",
    background: "brand.50",
    paddingX: 3,
    margin: 2,
    _hover: {
      background: "white",
    },
  };

  return (
    <NavigationBar
      position="sticky"
      top="0"
      zIndex={999}
      background="brand.400"
      color="brand.800"
      logo={
        <Link as={RouteLink} to="/">
          <CantonLogo canton={login?.canton ?? ""} width={logoWidth} />
        </Link>
      }
      boxShadow={boxShadow}
      isOpen={isOpen}
      onToggle={onToggle}
      onClose={onClose}
      paddingX={{ base: 4, lg: 6, xl: 8 }}
      paddingY={{ base: 2, lg: 4, xl: 6 }}
      textColor="gray.700"
    >
      <Button
        disabled={!!!login}
        as={RouteLink}
        to="/new"
        leftIcon={<GrEdit />}
        onClick={onClose}
        {...regularButtonProps}
      >
        <Text>{t("navbar.new")}</Text>
      </Button>

      <Button
        disabled={!!!login}
        as={RouteLink}
        to="/list"
        leftIcon={<GrList />}
        onClick={onClose}
        {...regularButtonProps}
      >
        <Text>{t("navbar.list")}</Text>
      </Button>

      <Button
        as={RouteLink}
        to="/help"
        leftIcon={<GrHelp />}
        onClick={onClose}
        {...regularButtonProps}
      >
        <Text>{t("navbar.help")}</Text>
      </Button>

      <LanguageSwitcher
        menuTextColor="gray.700"
        onMenuClose={onClose}
        {...regularButtonProps}
      />
    </NavigationBar>
  );
}
