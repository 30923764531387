"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.nameLocationSchema = void 0;
const zod_1 = require("zod");
const cantons_1 = require("./cantons");
exports.nameLocationSchema = zod_1.z.object({
    first: zod_1.z.string(),
    last: zod_1.z.string(),
    gender: zod_1.z.enum(["m", "f"]),
    plz: zod_1.z.string(),
    town: zod_1.z.string(),
    townNoCanton: zod_1.z.string(),
    canton: zod_1.z.enum(cantons_1.cantons),
    face: zod_1.z.string().optional(),
});
