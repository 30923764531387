import useLocalStorage from "@rehooks/local-storage";
import { infectionSchema, InfectionSchema } from "irs-shared";
import { localStorageKeys } from "../constants";

export function useReportUndo(): [
  InfectionSchema | null,
  (ir: InfectionSchema) => void,
  () => void
] {
  const [value, setValue, deleteValue] = useLocalStorage<InfectionSchema>(
    localStorageKeys.REPORT_UNDO
  );
  const parsed = infectionSchema.safeParse(value);
  if (parsed.success) {
    return [parsed.data, setValue, deleteValue];
  } else {
    if (value != null) {
      console.error(`Error parsing reportUndo: ${parsed.error}: ${value}`);
    }
    return [null, setValue, deleteValue];
  }
}
