"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.dummyInfection = exports.blankInfection = exports.infectionSchema = exports.infectionSchemaAttachments = exports.infectionSchemaComments = exports.infectionSchemaMeasures = exports.infectionSchemaExposition = exports.infectionSchemaDiagnosisPlain = exports.infectionSchemaExceptional = exports.infectionSchemaPatient = exports.z = void 0;
const zod_1 = require("zod");
Object.defineProperty(exports, "z", { enumerable: true, get: function () { return zod_1.z; } });
const constants_1 = require("./constants");
const cantons_1 = require("./cantons");
const history_1 = require("./history");
const zodHelper_1 = require("./zodHelper");
const attachments_1 = require("./attachments");
/**
 * The `patient` part of the infectionSchema
 */
exports.infectionSchemaPatient = zod_1.z.object({
    name: zod_1.z.string().min(1).max(constants_1.STRING_MAX),
    face: zod_1.z.string().optional(),
    dateOfBirth: zod_1.z.string().min(1).max(constants_1.STRING_MAX),
    sex: zod_1.z
        .enum(["", "MALE", "FEMALE", "OTHER"])
        .refine((data) => data !== "", "Required"),
    occupation: zod_1.z.string().min(1).max(constants_1.STRING_MAX),
    phoneNumber: zod_1.z.string().min(1).max(constants_1.STRING_MAX),
    nationality: zod_1.z.string().min(2, "Country code required").max(constants_1.STRING_MAX),
    domicil: zod_1.z
        .object({
        country: zod_1.z.string().min(2, "Country code required").max(constants_1.STRING_MAX),
        canton: zod_1.z
            .enum([...cantons_1.cantons, ""])
            .optional()
            .transform(zodHelper_1.removeIfEmpty),
        address: zod_1.z.string().min(1).max(constants_1.STRING_MAX),
    })
        .refine((data) => data.country.toUpperCase() !== "CH" || data.canton, {
        message: "Canton required for Switzerland",
        path: ["canton"],
    }),
});
/**
 * An optional text (for the exceptional infections)
 */
exports.infectionSchemaExceptional = zod_1.z
    .object({
    selected: zodHelper_1.booleanCheckbox,
    detail: zod_1.z.string().max(constants_1.STRING_MAX),
})
    .optional()
    .transform((data, ctx) => {
    if (data === null || data === void 0 ? void 0 : data.selected) {
        if (!data.detail) {
            ctx.addIssue({
                code: zod_1.z.ZodIssueCode.too_small,
                minimum: 1,
                type: "string",
                inclusive: true,
                message: "Write-in required",
            });
        }
        return data;
    }
    else {
        return undefined;
    }
});
/**
 * The `diagnosis` section without the outer transforms
 */
exports.infectionSchemaDiagnosisPlain = zod_1.z.object({
    anthrax: (0, zodHelper_1.writeIn)(["SKIN"]),
    botulism: (0, zodHelper_1.writeIn)(["FOOD"]),
    hemorrhagic_fever: (0, zodHelper_1.writeIn)(["LASSA", "EBOLA", "MARBURG", "KRIM-KONGO"]),
    pest: (0, zodHelper_1.writeIn)(["LUNG", "BUBONIC"]),
    influenza_a: zodHelper_1.booleanCheckboxTrueOrUndefined,
    sars: zodHelper_1.booleanCheckboxTrueOrUndefined,
    pox: zodHelper_1.booleanCheckboxTrueOrUndefined,
    exceptional: exports.infectionSchemaExceptional,
});
/**
 * The `exposition` section of the infectionSchema
 */
exports.infectionSchemaExposition = zod_1.z.object({
    kind: (0, zodHelper_1.basicSelectableWriteIn)([
        "HOSPITAL_DR",
        "LAB",
        "TRAVEL",
        "ANIMAL",
    ]).transform((data, ctx) => {
        if (!data || data.choice === "") {
            ctx.addIssue({
                code: zod_1.z.ZodIssueCode.custom,
                message: "Missing choice",
            });
        }
        else {
            if (data.choice === "OTHER" && data.other === "") {
                ctx.addIssue({
                    code: zod_1.z.ZodIssueCode.too_small,
                    minimum: 1,
                    type: "string",
                    inclusive: true,
                    message: "Write-in required",
                });
            }
        }
        return data;
    }),
    location: zod_1.z.string().min(1).max(constants_1.STRING_MAX),
});
/**
 * The `measures` section of the infectionSchema
 */
exports.infectionSchemaMeasures = zod_1.z.object({
    patient: zod_1.z.object({
        hospital: zod_1.z.object({
            name: zod_1.z.string().min(1).max(constants_1.STRING_MAX),
            phone: zod_1.z.string().min(1).max(constants_1.STRING_MAX),
            fax: zod_1.z.string().min(1).max(constants_1.STRING_MAX),
        }),
        isolated: zodHelper_1.action,
        therapy: zodHelper_1.actionMaybe,
    }),
    diagnosis: zod_1.z.object({
        lab: zod_1.z.object({
            name: zod_1.z.string().min(1).max(constants_1.STRING_MAX),
            phone: zod_1.z.string().min(1).max(constants_1.STRING_MAX),
            fax: zod_1.z.string().min(1).max(constants_1.STRING_MAX),
        }),
        informed: zodHelper_1.action,
    }),
    prevention: zod_1.z.object({
        personnel: zod_1.z.object({
            taken: zodHelper_1.action,
            measures: zod_1.z.string().min(1).max(constants_1.STRING_MAX),
        }),
        contacts: zod_1.z.object({
            identified: zodHelper_1.action,
            informed: zodHelper_1.action,
            prophylaxis: zodHelper_1.action,
            quarantine: zodHelper_1.action,
            aftercare: zodHelper_1.action,
        }),
        disinfection: zodHelper_1.action,
    }),
});
/**
 * The `comments` mini-section of the infectionSchema
 */
exports.infectionSchemaComments = zod_1.z.string().max(constants_1.STRING_MAX_LONG);
/**
 * The `attachments` mini-section of the infectionSchema
 */
exports.infectionSchemaAttachments = zod_1.z.array(attachments_1.attachmentSchema).optional();
/**
 * Schema representing the infection report form
 *
 * Refinements are used to add empty strings or sets as
 * valid for TypeScript, but as invalid for Zod. I.e., the form
 * can be pre-filled in TypeScript, but will require filling.
 */
exports.infectionSchema = zod_1.z.object({
    patient: exports.infectionSchemaPatient,
    diagnosis: exports.infectionSchemaDiagnosisPlain.refine(zodHelper_1.requireMultipleChoice1, "At least one required"),
    exposition: exports.infectionSchemaExposition,
    measures: exports.infectionSchemaMeasures,
    comments: exports.infectionSchemaComments,
    attachments: exports.infectionSchemaAttachments,
    history: history_1.historySchema.optional(),
});
/**
 * A blank valid (for Zod) infectionSchema to be used for
 * new forms.
 */
exports.blankInfection = {
    patient: {
        name: "",
        dateOfBirth: "",
        sex: "OTHER",
        occupation: "",
        phoneNumber: "",
        nationality: "",
        domicil: { country: "", address: "" },
    },
    diagnosis: {},
    exposition: {
        kind: { choice: "" },
        location: "",
    },
    measures: {
        patient: {
            hospital: { name: "", phone: "", fax: "" },
            isolated: { choice: "" },
            therapy: { choice: "" },
        },
        diagnosis: {
            lab: { name: "", phone: "", fax: "" },
            informed: { choice: "" },
        },
        prevention: {
            personnel: { taken: { choice: "" }, measures: "" },
            contacts: {
                identified: { choice: "" },
                informed: { choice: "" },
                prophylaxis: { choice: "" },
                quarantine: { choice: "" },
                aftercare: { choice: "" },
            },
            disinfection: { choice: "" },
        },
    },
    comments: "",
};
/**
 * A dummy infection record for tests
 */
exports.dummyInfection = {
    patient: {
        name: "A",
        dateOfBirth: "01.01.1970",
        sex: "OTHER",
        occupation: "B",
        phoneNumber: "C",
        nationality: "D",
        domicil: { country: "EF", address: "G" },
    },
    diagnosis: {
        sars: true,
    },
    exposition: {
        kind: { choice: "HOSPITAL_DR" },
        location: "H",
    },
    measures: {
        patient: {
            hospital: { name: "I", phone: "J", fax: "K" },
            isolated: { choice: "YES" },
            therapy: { choice: "YES" },
        },
        diagnosis: {
            lab: { name: "L", phone: "M", fax: "N" },
            informed: { choice: "YES" },
        },
        prevention: {
            personnel: { taken: { choice: "YES" }, measures: "O" },
            contacts: {
                identified: { choice: "YES" },
                informed: { choice: "YES" },
                prophylaxis: { choice: "YES" },
                quarantine: { choice: "YES" },
                aftercare: { choice: "YES" },
            },
            disinfection: { choice: "YES" },
        },
    },
    comments: "P",
};
