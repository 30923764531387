import { useMemo } from "react";
import { Box, Checkbox, HStack, VStack } from "@chakra-ui/react";
import { AxisOptions, Chart } from "react-charts";
import { infectionSchemaDiagnosisPlain, StatisticsSchema } from "irs-shared";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { useLanguage } from "../hooks/useLanguage";
import { useBooleanLocalStorage } from "../hooks/usePersistentBoolean";

// Days are clustered as 24h units relative to the current start of day
// Before/after a DST change, the "day" boundary will not be midnight,
// but off by (typically) 1h. For our use case, we plan to tolerate this.
const tzOffset = new Date().getTimezoneOffset() * 60 * 1000;

/**
 * Maps intra-day events to midnight; with the caveats from `tzOffset` above
 * @param millis The current time
 * @returns Midnight on the current day (±1h for typical DST configurations)
 */
function quantizeToMidnight(millis: number): number {
  const utcIntraday = millis % (86400 * 1000);
  const utcMidnight = millis - utcIntraday;
  if (tzOffset < 0) {
    // E.g. Europe/Zurich
    if (utcIntraday >= 86400 * 1000 - tzOffset) {
      return utcMidnight + 86400 * 1000; // Next day
    } else {
      return utcMidnight; // Same day
    }
  } else {
    if (utcIntraday < tzOffset) {
      return utcMidnight - 86200 * 1000; // Previous day
    } else {
      return utcMidnight; // Same day
    }
  }
}

type TimeValue = { time: number; value: number };
type TimeValueTransition = TimeValue & {
  cumulated?: number;
  value7?: number;
  cumulated7?: number;
};
type TimeValueCumulative = TimeValue & {
  cumulated: number;
  value7: number;
  cumulated7: number;
};
type DataArray = { label: string; data: TimeValueCumulative[] }[];

function cumulativize(tvArray: TimeValueTransition[]) {
  let cumulated = 0;
  let value7: number[] = [];
  let cumulated7: number[] = [];
  for (const tv of tvArray) {
    cumulated += tv.value;
    tv.cumulated = cumulated;

    // Running 7-day values, to be averaged
    // If fewer than 7 values have accumulated, they are averaged as well
    value7.push(tv.value);
    value7 = value7.slice(-7);
    cumulated7.push(cumulated);
    cumulated7 = cumulated7.slice(-7);
    tv.cumulated7 =
      cumulated7.reduce((prev, curr) => prev + curr) / cumulated7.length;
    tv.value7 = value7.reduce((prev, curr) => prev + curr) / value7.length;
  }
  return tvArray as TimeValueCumulative[];
}

function toCantonSeries(
  data: StatisticsSchema,
  translations: Record<string, string>
) {
  const cantonSeries: DataArray = [];
  for (const [c, d] of Object.entries(data.diagnosesByCanton)) {
    const timeSeries: Record<number, number> = {};
    for (
      let t = quantizeToMidnight(data.minMillis);
      t <= quantizeToMidnight(data.maxMillis);
      t += 86400 * 1000
    ) {
      timeSeries[t] = 0;
    }
    for (const tickLists of Object.values(d)) {
      for (const ticks of tickLists) {
        const day = quantizeToMidnight(ticks);
        timeSeries[day]++;
      }
    }
    const timeArray = Object.entries(timeSeries).map(([time, value]) => ({
      time: Number(time),
      value,
    }));
    // Is sorted as part of the initialization
    cantonSeries.push({
      label: c in translations ? translations[c] : c,
      data: cumulativize(timeArray),
    });
  }
  return cantonSeries;
}
function toInfectionSeries(
  data: StatisticsSchema,
  translations: Record<string, string>
) {
  const infectionSeries: DataArray = [];
  for (const infection of Object.keys(
    infectionSchemaDiagnosisPlain.keyof().enum
  ) as (keyof typeof infectionSchemaDiagnosisPlain)[]) {
    const timeSeries: Record<number, number> = {};
    for (
      let t = quantizeToMidnight(data.minMillis);
      t <= quantizeToMidnight(data.maxMillis);
      t += 86400 * 1000
    ) {
      timeSeries[t] = 0;
    }
    for (const d of Object.values(data.diagnosesByCanton)) {
      for (const ticks of Object.values(d[infection as keyof typeof d])) {
        const day = quantizeToMidnight(ticks);
        timeSeries[day]++;
      }
    }
    const timeArray = Object.entries(timeSeries).map(([time, value]) => ({
      time: Number(time),
      value,
    }));
    // Is sorted as part of the initialization
    infectionSeries.push({
      label: infection in translations ? translations[infection] : infection,
      data: cumulativize(timeArray),
    });
  }
  return infectionSeries;
}

export function CantonGraph(props: {
  data: StatisticsSchema;
  byInfection?: boolean;
}) {
  const [language] = useLanguage();
  dayjs.locale(language);
  const { t } = useTranslation("");
  const [cumul, setCumul] = useBooleanLocalStorage(
    props.byInfection ? "CUMULATIVE_INF" : "CUMULATIVE",
    false
  );
  const [avg, setAvg] = useBooleanLocalStorage(
    props.byInfection ? "AVERAGE_INF" : "AVERAGE",
    false
  );
  const data = useMemo(
    () =>
      props.byInfection
        ? toInfectionSeries(
            props.data,
            Object.fromEntries(
              Object.keys(infectionSchemaDiagnosisPlain.keyof().enum).map(
                (k) => [
                  k,
                  // Long form, if any, is in @tooltip
                  t([`statistics.${k}@tooltip`, `statistics.${k}`]),
                ]
              )
            )
          )
        : toCantonSeries(props.data, { "??": t("statistics.other") }),
    [props.data, props.byInfection, t]
  );
  const primaryAxis = useMemo<AxisOptions<TimeValueCumulative>>(
    () => ({
      getValue: (datum) => new Date(datum.time),
      scaleType: "localTime",
      formatters: {
        scale: (value) => dayjs(value).format("ll").replace(" 2022", ""),
      },
    }),
    []
  );
  const secondaryAxes = useMemo<AxisOptions<TimeValueCumulative>[]>(
    () => [
      {
        getValue: (datum) =>
          cumul
            ? avg
              ? datum.cumulated7
              : datum.cumulated
            : avg
            ? datum.value7
            : datum.value,
        stacked: true,
        elementType: cumul || avg ? "area" : "bar",
      },
    ],
    [cumul, avg]
  );

  // The last, empty <Box> is just because <Chart> sometimes draws out of bounds
  return (
    <VStack width="100%" alignItems="left">
      <HStack paddingY={2} spacing="2rem">
        <Checkbox
          key={`cumul`}
          isChecked={!!cumul}
          value={1}
          onChange={(e) => setCumul(e.currentTarget.checked)}
        >
          {t("statistics.cumulative")}
        </Checkbox>
        <Checkbox
          key={`avg7d`}
          isChecked={!!avg}
          value={1}
          onChange={(e) => setAvg(e.currentTarget.checked)}
        >
          {t("statistics.average")}
        </Checkbox>
      </HStack>
      <Box width="100%" height="30rem">
        <Chart options={{ data, primaryAxis, secondaryAxes }} />
      </Box>
      <Box height="2rem"></Box>
    </VStack>
  );
}
