import {
  Collapse,
  HStack,
  useDisclosure,
  VStack,
  Text,
  Box,
} from "@chakra-ui/react";
import { actionMaybe, infectionSchemaMeasures } from "irs-shared";
import { InnerBox } from "./InnerBox";
import { OuterBox } from "./OuterBox";
import { RadioChoices } from "./RadioChoices";
import { TextInput } from "./TextInput";
import { useTranslation } from "react-i18next";
import { HelpText } from "./HelpText";
import {
  DataZorm,
  discDataZorm,
  stringDataZorm,
  subDataZorm,
} from "../util/dataZorm";
import { ErrorText } from "./ErrorText";

/**
 * A sub-component for chosing whether anyone has to act and who it is
 * @param props
 * @returns
 */
export function ActionChoice(props: {
  dz: DataZorm<typeof actionMaybe>;
  nested?: boolean;
  colorScheme?: string;
  noBox?: boolean;
}) {
  const { dz, nested, colorScheme = "brand", noBox } = props;
  const { isOpen, onOpen, onClose } = useDisclosure({
    defaultIsOpen: ["NO", "MAYBE"].includes(dz.data.choice ?? ""),
  });
  const name = (dz.fields as any).choice().replace(/\.choice$/, "");
  const { t } = useTranslation("");
  const contents = (
    <>
      <VStack width="100%" justifyItems="left">
        <RadioChoices
          dz={dz}
          direction="row"
          very
          compact
          onChange={(nextValue) => {
            if (["NO", "MAYBE"].includes(nextValue)) {
              onOpen();
            } else {
              onClose();
            }
          }}
        />
        <HelpText name={name} colorScheme={colorScheme} />
        <ErrorText error={dz.errors()} />
        <Collapse in={isOpen} animateOpacity style={{ width: "100%" }}>
          <VStack
            width="100%"
            backgroundColor={nested ? "brand.100" : "brand.200"}
            paddingX={2}
            paddingY={2}
            rounded="xl"
          >
            <HStack
              width="100%"
              justifyContent="left"
              justifyItems="left"
              alignItems="top"
            >
              <Text width="100%">{t("todo")}</Text>
              <RadioChoices
                direction="column"
                dz={discDataZorm(dz as any, "NO", "todoFor")}
                textName="other"
                hideLabel
                selectedBold
                compact
              />
            </HStack>
            <ErrorText error={dz.errors.todoFor()} />
          </VStack>
        </Collapse>
      </VStack>
      <HelpText name={name} colorScheme={colorScheme} />
    </>
  );

  if (!!noBox) {
    return (
      <Box paddingY={2} width="100%">
        {contents}
      </Box>
    );
  } else {
    return <InnerBox nested={nested}>{contents}</InnerBox>;
  }
}

/**
 * The `measures` section of the infectionSchema
 * @param props
 * @returns
 */
export function Measures(props: {
  dz: DataZorm<typeof infectionSchemaMeasures>;
}) {
  const { dz } = props;
  const mp = subDataZorm(dz, "patient"); // measures.patient(!)
  const mph = subDataZorm(mp, "hospital");
  const md = subDataZorm(dz, "diagnosis");
  const mdl = subDataZorm(md, "lab");
  const mpv = subDataZorm(dz, "prevention");
  const mpvp = subDataZorm(mpv, "personnel");
  const mpvc = subDataZorm(mpv, "contacts");

  return (
    <OuterBox name="measures.title">
      <InnerBox name="measures.patient.title">
        <TextInput {...stringDataZorm(mph, "name")} />
        <TextInput {...stringDataZorm(mph, "phone")} />
        <TextInput {...stringDataZorm(mph, "fax")} />
        <VStack paddingTop={1} width="100%" spacing={2}>
          <ActionChoice dz={subDataZorm(mp, "isolated")} nested />
          <ActionChoice dz={subDataZorm(mp, "therapy")} nested />
        </VStack>
      </InnerBox>
      <InnerBox name="measures.diagnosis.title">
        <TextInput {...stringDataZorm(mdl, "name")} />
        <TextInput {...stringDataZorm(mdl, "phone")} />
        <TextInput {...stringDataZorm(mdl, "fax")} />
        <ActionChoice dz={subDataZorm(md, "informed")} nested />
      </InnerBox>
      <InnerBox name="measures.prevention.title">
        <VStack paddingTop={1} width="100%" spacing={2}>
          <InnerBox name="measures.prevention.personnel.title" nested>
            <ActionChoice dz={subDataZorm(mpvp, "taken")} />
            <TextInput {...stringDataZorm(mpvp, "measures")} paddingLeft={2} />
          </InnerBox>
          <InnerBox name="measures.prevention.contacts.title" nested>
            <ActionChoice dz={subDataZorm(mpvc, "identified")} nested noBox />
            <ActionChoice dz={subDataZorm(mpvc, "informed")} nested noBox />
            <ActionChoice dz={subDataZorm(mpvc, "prophylaxis")} nested noBox />
            <ActionChoice dz={subDataZorm(mpvc, "quarantine")} nested noBox />
            <ActionChoice dz={subDataZorm(mpvc, "aftercare")} nested noBox />
          </InnerBox>
          <ActionChoice dz={subDataZorm(mpv, "disinfection")} nested />
        </VStack>
      </InnerBox>
    </OuterBox>
  );
}
