import { Center, Text } from "@chakra-ui/react";

export function QueryError(props: { error: any }) {
  const { error } = props;
  console.debug(error);
  return (
    <Center width="100%">
      <Center
        width="20rem"
        height="10rem"
        border="solid red 3px"
        margin="1rem"
        backgroundColor="white"
      >
        <Text color="red" fontSize="xl">
          Data Fetching Error
        </Text>
      </Center>
    </Center>
  );
}
