import {
  Box,
  Button,
  Center,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Select,
  Spinner,
  Text,
  useBreakpointValue,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { FormEvent, useRef } from "react";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import {
  cantons,
  Cantons,
  isCanton,
  isUserId,
  LoginRequestSchema,
  userIds,
  UserIds,
  localDoctorNames,
} from "irs-shared";
import { useSchemaZorm } from "../util/dataZorm";
import { useLogin } from "../contexts/LoginContext";
import { brand } from "../App";
import { MaybeTooltip } from "./MaybeTooltip";
import { trpc } from "../util/trpc";

const loginFormSchema = z.object({
  user: z.enum(userIds),
  canton: z.enum(cantons),
});

async function randomLookingPasswordFor(s: string) {
  const alphabet =
    "abcdefghijkmnopqrstuvwxzyABCDEFGHJKLMPQRSTUVWXYZ123456789-!_$.%";
  const encoder = new TextEncoder();
  const data = encoder.encode(s);
  const hash = await crypto.subtle.digest("SHA-256", data);
  const hashArray = Array.from(new Uint8Array(hash));
  return hashArray.map((b) => alphabet[b % 64]).join("");
}

/**
 * A fake login form for the canton
 * @param props
 * @returns
 */
export function LoginForm(props: {}) {
  const [login, setLogin] = useLogin();
  const context = trpc.useContext();
  const auth = trpc.login.login.useMutation({
    onSuccess: (login) => {
      setLogin(login);
      context.queryClient.invalidateQueries();
      toast({
        status: "success",
        title: t("login.logged-in"),
        description: t("login.logged-in_body", {
          user: `${login.name} <${login.sub}>`,
        }),
      });
    },
  });
  const passwordSize = useBreakpointValue({ base: 10, sm: 16 });
  const pwField = useRef<HTMLInputElement>(null);
  const { t } = useTranslation("");
  const toast = useToast();

  async function demoPassword(account: string) {
    if (pwField.current) {
      pwField.current.value = (
        await randomLookingPasswordFor(account)
      ).substring(0, 16);
    }
  }

  function tryLogin(user: UserIds, canton: Cantons) {
    const input: LoginRequestSchema = { user, canton };
    demoPassword(`${user}@${canton}.ch`);
    auth.mutate(input);
  }

  const zo = useSchemaZorm("login", loginFormSchema, {
    onValidSubmit(e) {
      e.preventDefault();
      tryLogin(e.data.user, e.data.canton);
    },
  });

  function changeUser(event: FormEvent<HTMLSelectElement>) {
    const choice = event?.currentTarget.value;
    if (isUserId(choice) && login?.canton) {
      tryLogin(choice, login.canton);
    }
  }
  function changeCanton(event: FormEvent<HTMLSelectElement>) {
    const choice = event?.currentTarget.value;
    if (isCanton(choice) && login?.user) {
      tryLogin(login.user, choice);
    }
  }

  const doctorNames = localDoctorNames(login?.canton ?? "ZH");

  return (
    <Center
      width="min(100vw, 40rem)"
      paddingX={2}
      minHeight="10rem"
      textAlign="center"
    >
      <form name="login" ref={zo.ref} style={{ width: "100%" }}>
        <VStack
          width="100%"
          rounded="xl"
          bgColor="#ee9"
          paddingY="2rem"
          position="relative"
        >
          <Heading>
            {!!login ? (
              <>
                {t("login.as_active")}{" "}
                <span style={{ color: brand[800] }}>{login.name}</span>
              </>
            ) : (
              t("login.as")
            )}
          </Heading>
          <HStack
            fontSize="2xl"
            color="brand.900"
            fontWeight="bold"
            spacing="0"
            paddingY={3}
          >
            <Select
              key="user"
              name={zo.fields.user()}
              placeholder="🠋"
              width={{ base: "12rem", sm: "15rem", md: "20rem" }}
              fontSize={{ base: "1.2rem", md: "1.5rem" }}
              maxWidth="60%"
              height="2em"
              defaultValue={login?.user ?? ""}
              onChange={changeUser}
              backgroundColor="brand.100"
              size="xl"
              fontWeight="bold"
              borderWidth="1px"
              borderStyle="solid"
              borderColor="brand.400"
            >
              {userIds.map((c) => (
                <option key={c} value={c}>
                  {`${doctorNames[c]} (${c})`}
                </option>
              ))}
            </Select>
            <Text>@</Text>
            <Select
              key="canton"
              name={zo.fields.canton()}
              placeholder="🠋"
              width={{ base: "4.5rem", md: "6rem" }}
              fontSize={{ base: "1.2rem", md: "1.5rem" }}
              height="2em"
              defaultValue={login?.canton ?? ""}
              onChange={changeCanton}
              backgroundColor="brand.100"
              size="xl"
              fontWeight="bold"
              borderWidth="1px"
              borderStyle="solid"
              borderColor="brand.400"
            >
              {cantons.map((c) => (
                <option key={c} value={c}>
                  {c}
                </option>
              ))}
            </Select>
            <Text>{".ch"}</Text>
          </HStack>
          <FormControl>
            <Center>
              <HStack align="baseline">
                <FormLabel>
                  <MaybeTooltip name="login.password">
                    <Text fontWeight="bold" fontSize="2xl">
                      {t("login.password")}
                    </Text>
                  </MaybeTooltip>
                </FormLabel>
                <Input
                  backgroundColor="#ffd"
                  size={{ base: "md", sm: "lg" }}
                  htmlSize={passwordSize}
                  fontWeight="bold"
                  fontFamily="mono"
                  isReadOnly
                  _disabled={{
                    backgroundColor: "yellow.100",
                    color: "brand.800",
                  }}
                  type="text"
                  textOverflow="ellipsis ellipsis"
                  defaultValue="••••••••••"
                  ref={pwField}
                />
              </HStack>
            </Center>
          </FormControl>
          <Text
            visibility={zo.validation?.success !== false ? "hidden" : undefined}
            color="red"
          >
            {t("errors.message.Missing choice")}
          </Text>
          {!!!login ? (
            <Button
              key="login"
              border="solid 3px"
              type="submit"
              isLoading={auth.isLoading}
              isDisabled={zo.validation?.success === false}
            >
              <Text>{t("login.login")}</Text>
            </Button>
          ) : (
            <Button
              key="logout"
              border="2px"
              onClick={() => {
                setLogin(null);
                toast({
                  status: "success",
                  title: t("login.logged-out"),
                  description: t("login.logged-out_body"),
                });
              }}
            >
              <Text>{t("login.logout")}</Text>
            </Button>
          )}
          <Box
            top="0"
            left="0"
            width="100%"
            height="100%"
            position="absolute"
            backgroundColor="whiteAlpha.500"
            visibility={!!!login || !auth.isLoading ? "hidden" : undefined}
          >
            <Spinner
              position="absolute"
              top="50%"
              left="50%"
              size="xl"
              thickness="4px"
              color="brand.700"
              label="Loading..."
            />
          </Box>
        </VStack>
      </form>
    </Center>
  );
}
