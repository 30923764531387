"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.loginInfoSchema = exports.loginRequestSchema = exports.roles = void 0;
const zod_1 = require("zod");
const cantons_1 = require("./cantons");
const demoUsers_1 = require("./demoUsers");
exports.roles = ["dr", "cs"];
exports.loginRequestSchema = zod_1.z.object({
    user: zod_1.z.enum(demoUsers_1.userIds),
    canton: zod_1.z.enum(cantons_1.cantons),
});
/**
 * Information to the client:
 * - sub (subject, account): "dr1@zh.ch", …
 * - name: "Kantonsarzt", "Doktor Eins", …
 * - fullName: "Kantonsarzt ZH", "Doktor Eins ZH", …"
 * - user: "dr1", …
 * - role: "cs", "dr"
 * - canton: "ZH", …
 * - bearerToken: JWT
 */
exports.loginInfoSchema = zod_1.z.object({
    sub: zod_1.z.string(),
    name: zod_1.z.string(),
    fullName: zod_1.z.string(),
    user: zod_1.z.enum(demoUsers_1.userIds),
    role: zod_1.z.enum(exports.roles),
    canton: zod_1.z.enum(cantons_1.cantons),
    bearerToken: zod_1.z.string(),
});
