"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isCanton = exports.cantonLanguages = exports.ExtendedCantonSchema = exports.extendedCantons = exports.CantonSchema = exports.cantons = void 0;
const zod_1 = require("zod");
exports.cantons = [
    "ZH",
    "BE",
    "LU",
    "UR",
    "SZ",
    "OW",
    "NW",
    "GL",
    "ZG",
    "FR",
    "SO",
    "BS",
    "BL",
    "SH",
    "AR",
    "AI",
    "SG",
    "GR",
    "AG",
    "TG",
    "TI",
    "VD",
    "VS",
    "NE",
    "GE",
    "JU",
];
exports.CantonSchema = zod_1.z.enum(exports.cantons);
exports.extendedCantons = [...exports.cantons, "??"];
exports.ExtendedCantonSchema = zod_1.z.enum(exports.extendedCantons);
const de = ["de"];
const fr = ["fr"];
exports.cantonLanguages = {
    ZH: de,
    BE: ["de", "fr"],
    LU: de,
    UR: de,
    SZ: de,
    OW: de,
    NW: de,
    GL: de,
    ZG: de,
    FR: fr,
    SO: de,
    BS: de,
    BL: de,
    SH: de,
    AR: de,
    AI: de,
    SG: de,
    GR: ["rm", "de", "it"],
    AG: de,
    TG: de,
    TI: ["it"],
    VD: fr,
    VS: ["fr", "de"],
    NE: fr,
    GE: fr,
    JU: fr,
};
function isCanton(c) {
    return exports.cantons.includes(c);
}
exports.isCanton = isCanton;
