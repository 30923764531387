import { EditIcon } from "@chakra-ui/icons";
import { IconButton, Tooltip } from "@chakra-ui/react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { EditingContext } from "../contexts/EditingContext";

export function MakeEditable(props: { preventEditing?: boolean }) {
  const { preventEditing } = props;
  const { editing, editable, setEditing } = useContext(EditingContext);
  const { t } = useTranslation("");

  if (editing || !editable || preventEditing) {
    return null;
  } else {
    return (
      <Tooltip label={t("make-editable")}>
        <IconButton
          height="3rem"
          width="3rem"
          border={0}
          padding={0}
          roundedTopRight="xl"
          _hover={{ backgroundColor: "brand.600" }}
          aria-label={t("make-editable")}
          icon={<EditIcon color="white" width="1.5rem" height="1.5rem" />}
          onClick={() => setEditing(true)}
        />
      </Tooltip>
    );
  }
}
