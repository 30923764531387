"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.attachmentDBSchema = exports.attachmentSchema = exports.attachmentBaseSchema = void 0;
const zod_1 = require("zod");
const zodHelper_1 = require("./zodHelper");
exports.attachmentBaseSchema = zod_1.z.object({
    by: zod_1.z.string(),
    at: zodHelper_1.numberInput,
    name: zod_1.z.string(),
});
exports.attachmentSchema = exports.attachmentBaseSchema.extend({
    document: zod_1.z.string().uuid(),
});
exports.attachmentDBSchema = exports.attachmentBaseSchema.extend({
    filename: zod_1.z.string().optional(),
});
