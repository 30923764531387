import { useLocalStorage } from "@rehooks/local-storage";
import { localStorageKeys } from "../constants";

const lngOptions = ["de", "fr", "en"] as const;
export const possibleLngs = { de: "Deutsch", fr: "Français", en: "English" };
type LngOptions = typeof lngOptions[number];

export function isLng(s: string): s is LngOptions {
  return possibleLngs.hasOwnProperty(s);
}

// Language selection priority:
// 1: Saved in `localStorage['language']` (explicitely switched by user; highest)
// 2: Specified in `language` query string (for search engines `hreflang`)
// 3: Browser language settings (regular users)
function browserLanguage(): LngOptions {
  // Query string override?
  const urlSearchParams = new URLSearchParams(globalThis.location.search);
  const queryLanguage = urlSearchParams.get("language");
  if (queryLanguage && isLng(queryLanguage)) {
    return queryLanguage;
  }
  for (let l of navigator?.languages || []) {
    // Handling exact matches
    if (isLng(l)) {
      return l;
    }
    // Handling matches of the first part of a 'de-CH' etc. browser language
    const dash = l.indexOf("-");
    if (dash >= 0) {
      const partialLng = l.substring(0, dash);
      if (isLng(partialLng)) {
        return partialLng;
      }
    }
  }
  return lngOptions[0];
}

export function useLanguage(): [
  language: LngOptions,
  setLanguage: (newLng: LngOptions) => void
] {
  let [language, setLanguage] = useLocalStorage<LngOptions>(
    localStorageKeys.LANGUAGE
  );
  language ??= browserLanguage();
  return [language in possibleLngs ? language : lngOptions[0], setLanguage];
}
