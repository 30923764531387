import React from "react";
import {
  Button,
  ButtonProps,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text,
} from "@chakra-ui/react";
import { MdLanguage } from "react-icons/md";
import { possibleLngs, useLanguage } from "../hooks/useLanguage";

type LanguageSwitcherProps = ButtonProps & {
  menuTextColor: string;
  onMenuClose: () => void;
};

export function LanguageSwitcher(props: LanguageSwitcherProps) {
  const { menuTextColor, onMenuClose, ...regularButtonProps } = props;
  const [language, setLanguage] = useLanguage();

  function onLanguageSelection(language: any) {
    onMenuClose();
    setLanguage(language);
  }
  return (
    <Menu>
      <MenuButton
        as={Button}
        textTransform="uppercase"
        leftIcon={<MdLanguage />}
        {...regularButtonProps}
      >
        <Text>{language}</Text>
      </MenuButton>

      <MenuList color={menuTextColor}>
        <MenuOptionGroup
          type="radio"
          defaultValue={language}
          onChange={onLanguageSelection}
        >
          {Object.entries(possibleLngs).map(([k, v]) => (
            <MenuItemOption value={k} key={k}>
              {v}
            </MenuItemOption>
          ))}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  );
}
