import {
  Box,
  Center,
  Flex,
  Heading,
  IconButton,
  Image,
  Link,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  TableContainer,
  Text,
  Tooltip,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { ReactNode, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouteLink } from "react-router-dom";
import { FiMaximize, FiMinimize } from "react-icons/fi";
import { createColumnHelper } from "@tanstack/react-table";
import { z } from "zod";
import dayjs from "dayjs";
import { SummariesSeenSchema, summarySeenSchema } from "irs-shared";
import { CenteredSpinner } from "./CenteredSpinner";
import { useMaximized } from "../hooks/usePersistentBoolean";
import { useLanguage } from "../hooks/useLanguage";
import { LoginState, useLogin } from "../contexts/LoginContext";
import newQR from "../assets/new.svg";
import seenQR from "../assets/seen.svg";
import updatedQR from "../assets/updated.svg";
import { DataTable } from "./DataTable";
import { MaybeTooltip } from "./MaybeTooltip";
import { BienSurQRCode } from "./BienSurQRCode";
import { trpc } from "../util/trpc";
import { localStorageKeys } from "../constants";

function shortDate(millis: number, prefix: string = "") {
  let dateDesc: string;
  const now = Date.now();
  if (millis > now + 60 * 1000) {
    // Future (outside 1 minute time skew)
    dateDesc = "???";
  } else if (millis > now - 12 * 60 * 60 * 1000) {
    // Within past 12 hours
    dateDesc = dayjs(millis).format("HH:mm");
  } else if (millis > now - 180 * 86400 * 1000) {
    // Within past ~6 months: Day
    dateDesc = dayjs(millis).format("DD.MM.");
  } else if (millis > 0) {
    // Older
    dateDesc = dayjs(millis).format("YYYY");
  } else {
    return "—";
  }
  return (
    <Tooltip label={prefix + dayjs(millis).format("llll")}>{dateDesc}</Tooltip>
  );
}

export function VanishingTooltip(props: {
  label: string;
  isDisabled?: boolean;
  children: ReactNode;
}) {
  const { label, isDisabled, children } = props;
  if (isDisabled) {
    return <>{children}</>;
  } else {
    return <Tooltip label={label}>{children}</Tooltip>;
  }
}

// Also has conventient alphabetical sorting: new < updated < seen
type SeenState = "new" | "seen" | "updated";

const modifiedQR: Record<SeenState, string> = {
  new: newQR,
  seen: seenQR,
  updated: updatedQR,
};

type ReportListData = z.infer<typeof summarySeenSchema>;

const columnHelper = createColumnHelper<ReportListData>();

function updateSummaries(
  prev: SummariesSeenSchema,
  next: SummariesSeenSchema,
  context: ReturnType<typeof trpc["useContext"]>
): SummariesSeenSchema {
  console.log(prev, next);
  const prevs = Object.fromEntries(prev.map((v) => [v.id, v]));
  const nexts = Object.fromEntries(next.map((v) => [v.id, v]));
  const keys = Array.from(
    new Set([...Object.keys(prevs), ...Object.keys(nexts)])
  );
  const result: SummariesSeenSchema = [];
  keys.sort();
  for (const k of keys) {
    if (k in prevs) {
      if (k in nexts) {
        if (prevs[k].lastModified === nexts[k].lastModified) {
          // Unmodified (or only seen state modified)
          // Explicitely unset the modified flag
          console.log(`Keeping ${nexts[k].name} (${k})`);
          result.push({ ...nexts[k], modified: undefined });
        } else {
          // Changed
          console.log(`Changing ${nexts[k].name} (${k})`);
          result.push({ ...nexts[k], modified: "changed" });
          context.queryClient.invalidateQueries(["list.get", nexts[k].id]);
          console.log(`Invalidated ${nexts[k].id}`);
        }
      } else {
        // Deleted
        if (prevs[k].modified !== "deleted") {
          console.log(`"Deleting" ${prevs[k].name} (${k})`);
          // Keep with "deleted" status until the next change
          result.push({ ...prevs[k], modified: "deleted" });
          context.queryClient.invalidateQueries(["list.get", prevs[k].id]);
          console.log(`Invalidated ${prevs[k].id}`);
        } else {
          console.log(`Removing ${prevs[k].name} (${k})`);
          // When already marked "deleted" during the previous update:
          // Get rid of it entirely now (do not copy it to the result)
        }
      }
    } else {
      // Added
      console.log(`Adding ${nexts[k].name} (${k})`);
      result.push({ ...nexts[k], modified: "added" });
    }
  }
  console.log(result);
  return result;
}

export function ReportListLoggedIn(props: { login: LoginState }) {
  // Is it necessary to pass the login to refresh on user change?
  const summaries = trpc.list.get.useQuery(undefined, {
    refetchOnWindowFocus: false,
  });
  const context = trpc.useContext();
  trpc.list.watch.useSubscription(props.login!.bearerToken, {
    enabled: !!props.login,
    onData: (data) => {
      context.queryClient.setQueryData(
        ["list.get"],
        (prev: SummariesSeenSchema | undefined) =>
          updateSummaries(prev ?? [], data, context)
      );
    },
  });
  const reallyDelete = useCallback(() => {
    if (summaries.data) {
      const newSummaries = summaries.data.filter(
        (s) => s.modified !== "deleted"
      );
      if (newSummaries.length !== summaries.data.length) {
        // Any deleted?
        context.queryClient.setQueryData(["list.get"], newSummaries);
      }
    }
  }, [summaries, context.queryClient]);
  const [maximized, setMaximized] = useMaximized();
  const [language] = useLanguage();
  const { t } = useTranslation("");
  const [qr, setQr] = useState<{ path: string; name: string }>({
    path: "",
    name: "",
  });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const lastModifiedPrefix = t("report.lastModifiedPrefix") + " ";
  dayjs.locale(language);

  const columns = [
    columnHelper.accessor(
      (row) =>
        (row.seen == null
          ? "new"
          : row.seen >= row.lastModified
          ? "seen"
          : "updated") as SeenState,
      {
        id: "qr",
        cell: (info) => {
          const modifiedState = info.getValue();
          return (
            <VanishingTooltip
              label={t(`seen.${modifiedState}`)}
              isDisabled={isOpen}
            >
              <Image
                width="1em"
                height="1em"
                src={modifiedQR[modifiedState]}
                onClick={() =>
                  qrModal(info.row.original.id, info.row.original.name)
                }
              />
            </VanishingTooltip>
          );
        },
        header: () => (
          <MaybeTooltip name="report.qr">{t("report.qr")}</MaybeTooltip>
        ),
      }
    ),
    columnHelper.accessor("lastModified", {
      id: "lastModified",
      cell: (info) => shortDate(info.getValue(), lastModifiedPrefix),
      header: () => (
        <MaybeTooltip name={"report.date"}>{t("report.date")}</MaybeTooltip>
      ),
    }),
    columnHelper.accessor("name", {
      id: "name",
      cell: (info) => (
        <Link
          as={RouteLink}
          key={info.row.id}
          href={`/report/${info.row.original.id}`}
          to={`/report/${info.row.original.id}`}
        >
          {info.getValue()}
        </Link>
      ),
      header: t("patient.name") as string,
    }),
    columnHelper.accessor("town", {
      id: "town",
      cell: (info) => info.getValue(),
      header: t("patient.domicil.address") as string,
    }),
    columnHelper.accessor("diagnosis", {
      id: "diagnosis",
      cell: (info) =>
        info
          .getValue()
          .map((d) => t(`diagnosis.${d}`))
          .join(", "),
      header: t("diagnosis.title") as string,
    }),
  ];

  function qrModal(path: string, name: string) {
    setQr({ path: `${document.location.origin}/report/${path}`, name });
    onOpen();
  }

  return (
    <Center
      width={maximized ? "min(100vw, 100%)" : "min(100vw, 40rem)"}
      paddingX={2}
    >
      <VStack
        width="100%"
        maxHeight={maximized ? "80vh" : "max(20rem, 30vh)"}
        paddingBottom="2rem"
        bgColor="brand.200"
        rounded="xl"
      >
        <Flex width="100%">
          <Box width="2rem" />
          <Spacer />
          <Heading marginTop="2rem" textAlign="center">
            {t("report.title")}
          </Heading>
          <Spacer />
          <IconButton
            borderStyle="none"
            aria-label="maximize"
            icon={
              maximized ? (
                /*
                 * Results in React warnings in the browser:
                 * "Unexpected value 1.5rem parsing height attribute." and
                 * "Unexpected value 1.5rem parsing width attribute.".
                 * However, there does not seem to be another way to make
                 * it the right size…
                 */
                <FiMinimize size="1.5rem" />
              ) : (
                <FiMaximize size="1.5rem" />
              )
            }
            onClick={() => {
              setMaximized(!maximized);
            }}
          />
        </Flex>

        {summaries.isLoading ? (
          <CenteredSpinner />
        ) : summaries.isError ? (
          <Text size="xl">{t("report.bad-format")}</Text>
        ) : !summaries || summaries.data.length === 0 ? (
          <Text size="xl">{t("report.no-records")}</Text>
        ) : (
          <TableContainer width="100%" overflowY="auto">
            <DataTable
              columns={columns}
              data={summaries.data}
              persistSortAs={localStorageKeys["REPORTS_SORT"]}
              initialSort={[{ id: "qr", desc: true }]}
              reallyDelete={reallyDelete}
            />
          </TableContainer>
        )}
      </VStack>
      <Modal isCentered size="xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay
          bg="rgba(83,150,73,0.4)"
          backdropFilter="blur(10px) grayscale(50%)"
        />
        <ModalContent>
          <ModalHeader fontSize="3xl">{`${t("report.qr-code")} ${
            qr.name
          }`}</ModalHeader>
          <ModalCloseButton />
          <Center padding={3}>
            <BienSurQRCode value={qr.path} />
          </Center>
          <ModalFooter>
            <Text>{t("report.qr-code_body")}</Text>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Center>
  );
}

export function ReportList() {
  const [login] = useLogin();
  const [maximized] = useMaximized();
  const { t } = useTranslation("");

  if (!!!login) {
    return (
      <Center
        width={maximized ? "min(100vw, 100%)" : "min(100vw, 40rem)"}
        paddingX={2}
        textAlign="center"
      >
        <VStack width="100%" paddingY="2rem" bgColor="red.200" rounded="xl">
          <Heading>{t("report.title")}</Heading>
          <Text size="xl">{t("login.not-login")}</Text>
        </VStack>
      </Center>
    );
  } else {
    return <ReportListLoggedIn login={login} />;
  }
}
