import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import localizedFormat from "dayjs/plugin/localizedFormat";
import "dayjs/locale/de";
import "dayjs/locale/en";
import "dayjs/locale/fr";
import { useLanguage } from "../hooks/useLanguage";
import { Tooltip } from "@chakra-ui/react";

dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);

export function DateText(props: { millis: number }) {
  const [language] = useLanguage();
  dayjs.locale(language);
  return (
    <Tooltip label={dayjs(props.millis).format("llll")}>
      {dayjs(props.millis).fromNow()}
    </Tooltip>
  );
}
