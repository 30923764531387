import {
  VStack,
  Heading,
  Box,
  Checkbox,
  Collapse,
  useDisclosure,
} from "@chakra-ui/react";
import {
  infectionSchemaDiagnosisPlain,
  infectionSchemaExceptional,
  z as z2, // The zod used in shared is a different zod, having different classes (even though they consist of identical code) and therefore spinning off different instances
} from "irs-shared";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { checkboxTheme } from "../App";
import { EditingContext } from "../contexts/EditingContext";
import { DataZorm, unionDataZorm, reveal, subDataZorm } from "../util/dataZorm";
import { ErrorText } from "./ErrorText";
import { HelpText } from "./HelpText";
import { MaybeTooltip } from "./MaybeTooltip";
import { MultipleChoiceWithRefinement } from "./MultipleChoiceWithRefinement";
import { OuterBox } from "./OuterBox";
import { TextInput } from "./TextInput";

/**
 * Single choice component
 * @param props `stringDataZorm` and `colorScheme`
 * @returns component
 */
export function SingleChoice(props: {
  name: string;
  error?: z.ZodIssue;
  defaultValue: any;
  colorScheme?: string;
}) {
  const { name, error, defaultValue, colorScheme = "brand" } = props;
  const { t } = useTranslation("");
  const { editing } = useContext(EditingContext);

  return (
    <Box backgroundColor="brand.300" width="100%" paddingTop={3} rounded="xl">
      <VStack width="100%" paddingX={3}>
        <Checkbox
          isDisabled={!editing}
          key={`${name}_${defaultValue}`}
          defaultChecked={!!defaultValue}
          value="1"
          name={name}
          justifySelf="left"
          width="100%"
          {...checkboxTheme}
        >
          <Heading size={{ base: "md", md: "lg" }}>
            <MaybeTooltip name={name}>{t(name)}</MaybeTooltip>
          </Heading>
        </Checkbox>
        <HelpText name={name} colorScheme={colorScheme} />
        <ErrorText error={error} />
        <Box width="100%" paddingLeft={3} />
      </VStack>
    </Box>
  );
}

/**
 * A selection with an additional explanation
 * @param props `dataZorm` (dz) and `colorScheme`
 * @returns component
 */
export function ExplanationChoice(props: {
  dz: DataZorm<typeof infectionSchemaExceptional>;
  colorScheme: string;
}) {
  const { dz, colorScheme } = props;
  const { isOpen, onToggle } = useDisclosure({
    defaultIsOpen: dz.data?.selected ?? false,
  });
  const { t } = useTranslation("");
  const { editing } = useContext(EditingContext);
  const name = dz.fields.selected().replace(/\.selected$/, "");
  // { /*...stringDataZorm(dz, "detail")*/ }
  return (
    <Box backgroundColor="brand.300" width="100%" paddingTop={3} rounded="xl">
      <VStack width="100%" paddingX={3}>
        <Checkbox
          key={`${dz.fields.selected()}_${dz.data?.selected}_${isOpen}`}
          isDisabled={!editing}
          checked={isOpen}
          name={dz.fields.selected()}
          defaultChecked={isOpen}
          value="1"
          justifySelf="left"
          width="100%"
          onChange={onToggle}
          {...checkboxTheme}
        >
          <Heading size={{ base: "md", md: "lg" }}>{t(name)}</Heading>
        </Checkbox>
        <HelpText name={name} colorScheme={colorScheme} />
        <Box width="100%" paddingLeft={3}>
          <Collapse in={isOpen} animateOpacity>
            <TextInput
              name={dz.fields.detail()}
              error={dz.errors.detail()}
              defaultValue={dz.data?.detail}
              hideLabel
            />
            <Box height={3} />
            <ErrorText error={dz.errors()} />
          </Collapse>
        </Box>
      </VStack>
    </Box>
  );
}

/**
 * The diagnosis section
 * @param props `dataZorm` and `colorScheme`
 * @returns component
 */
export function Diagnosis(props: {
  dz: DataZorm<typeof infectionSchemaDiagnosisPlain>;
  colorScheme?: string;
}) {
  const { dz, colorScheme = "brand" } = props;

  return (
    <OuterBox name="diagnosis.title">
      {Object.keys(infectionSchemaDiagnosisPlain.shape).map((kk) => {
        const k = kk as keyof z.infer<typeof infectionSchemaDiagnosisPlain>;
        const inner = reveal(dz, k);

        if (inner instanceof z2.ZodDiscriminatedUnion) {
          // The easiest (but also probably ugliest) is to cast to a specimen of the right type.
          // Anything else requires huge amounts of code, apparently.
          return (
            <MultipleChoiceWithRefinement
              key={k}
              dz={subDataZorm(dz, k as "anthrax")}
              textName="other"
            />
          );
        } else if (inner instanceof z2.ZodUnion) {
          return (
            <SingleChoice
              key={k}
              {...unionDataZorm(dz, k as "sars")}
              colorScheme={colorScheme}
            />
          );
        } else if (inner instanceof z2.ZodObject) {
          return (
            <ExplanationChoice
              key={k}
              dz={subDataZorm(dz, k as "exceptional")}
              colorScheme={colorScheme}
            />
          );
        } else {
          console.warn("Unhandled diagnosis type: ", k, inner._def.typeName);
          return null;
        }
      })}
      <ErrorText error={dz.errors()} />
    </OuterBox>
  );
}
