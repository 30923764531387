import {
  FormControl,
  VStack,
  HStack,
  FormLabel,
  RadioGroup,
  Radio,
  Text,
  Stack,
} from "@chakra-ui/react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { radioTheme } from "../App";
import { EditingContext } from "../contexts/EditingContext";
import { ErrorText } from "./ErrorText";
import { HelpText } from "./HelpText";
import { MaybeTooltip } from "./MaybeTooltip";

/**
 * A legacy radio button choice
 * @param props
 * @returns
 */
export function SingleLineRadioChoice(props: {
  name: string;
  error?: z.ZodIssue;
  defaultValue?: string;
  choices: [string, ...string[]];
  colorScheme?: string;
}) {
  const { name, error, defaultValue, choices, colorScheme = "brand" } = props;
  const { t } = useTranslation("");
  const { editing } = useContext(EditingContext);

  return (
    <FormControl as="fieldset" width="100%">
      <VStack width="100%">
        <HStack
          spacing="0"
          justify="space-between"
          width="100%"
          alignItems="baseline"
        >
          <FormLabel
            as="legend"
            marginTop={2}
            fontSize={{ base: "xl", md: "2xl" }}
          >
            {t(name)}
          </FormLabel>
          <RadioGroup
            isDisabled={!editing}
            name={name}
            defaultValue={defaultValue}
            key={`${name}_${defaultValue}`}
          >
            <Stack
              spacing={{ base: -2, sm: 3 }}
              direction={{ base: "column", sm: "row" }}
            >
              {choices.map((v) => {
                if (v === "") {
                  return null;
                } else {
                  return (
                    <Radio
                      key={v}
                      value={v}
                      size={{ base: "md", md: "lg" }}
                      {...radioTheme}
                    >
                      <MaybeTooltip name={v}>
                        <Text
                          paddingLeft={-1}
                          paddingY={1}
                          fontSize={{ base: "xl", md: "2xl" }}
                        >
                          {t(v)}
                        </Text>
                      </MaybeTooltip>
                    </Radio>
                  );
                }
              })}
            </Stack>
          </RadioGroup>
        </HStack>
        <HelpText name={name} colorScheme={colorScheme} />
        <ErrorText error={error} />
      </VStack>
    </FormControl>
  );
}
