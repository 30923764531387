"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isUserId = exports.localDoctorNames = exports.localDoctorName = exports.localCsName = exports.csName = exports.numTranslations = exports.drTranslations = exports.csTranslations = exports.userIds = void 0;
const cantons_1 = require("./cantons");
exports.userIds = ["cs", "dr1", "dr2", "dr3"];
exports.csTranslations = {
    de: "Kantonsarzt",
    fr: "Medecin Cantonal",
    it: "Medico Cantonale",
    rm: "Docter Chantunal",
};
exports.drTranslations = {
    de: "Doktor",
    fr: "Docteur",
    it: "Dottore",
    rm: "Docter",
};
exports.numTranslations = {
    de: ["Eins", "Zwei", "Drei"],
    fr: ["Une", "Deux", "Trois"],
    it: ["Uno", "Due", "Tre"],
    rm: ["In", "Dus", "Trais"],
};
function csName(lng) {
    return exports.csTranslations[lng];
}
exports.csName = csName;
function localCsName(canton) {
    const idxLng = cantons_1.cantonLanguages[canton][0];
    return csName(idxLng);
}
exports.localCsName = localCsName;
/**
 * Returns the name of a demo doctor in that language
 * @param lng The language
 * @param idx Which of the demo names
 * @returns Doctor name
 */
function doctorName(lng, idx) {
    return `${exports.drTranslations[lng]} ${exports.numTranslations[lng][idx]}`;
}
/**
 * Returns the name of a demo doctor in that canton (using all languages)
 * @param canton The canton
 * @param idx Which of the demo names
 * @returns Local doctor name
 */
function localDoctorName(canton, idx) {
    const idxLng = cantons_1.cantonLanguages[canton][(idx + 1) % cantons_1.cantonLanguages[canton].length];
    return doctorName(idxLng, idx);
}
exports.localDoctorName = localDoctorName;
/**
 * Returns a set of demo names for the functions
 * @param canton The canton
 * @returns The set of demo names/functions
 */
function localDoctorNames(canton) {
    return {
        cs: exports.csTranslations[cantons_1.cantonLanguages[canton][0]],
        dr1: localDoctorName(canton, 0),
        dr2: localDoctorName(canton, 1),
        dr3: localDoctorName(canton, 2),
    };
}
exports.localDoctorNames = localDoctorNames;
function isUserId(u) {
    return exports.userIds.includes(u);
}
exports.isUserId = isUserId;
