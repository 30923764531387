"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QRCode = exports.svgDrawingCommandsFromQrCode = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const qrcodegen_1 = require("./qrcodegen");
function runLengthQr(qr) {
    const size = qr.size;
    const runs = Array(size);
    for (const y of Array(size).keys()) {
        const line = [];
        let x = 0;
        while (x < size) {
            // Skip over (first, next) white run
            while (x < size && qr.getModule(x, y) === false) {
                x++;
            }
            if (x < size) {
                // A black run starts here
                const start = x;
                while (x < size && qr.getModule(x, y) === true) {
                    x++;
                }
                line.push({ start, end: x });
            }
        }
        runs[y] = line;
    }
    return runs;
}
function svgPaths(runs, offset) {
    const paths = [];
    for (const [y, line] of runs.entries()) {
        let path = "";
        let prevX = undefined;
        for (const run of line.values()) {
            // Draw the top line of the rectangle
            if (prevX === undefined) {
                path = `M${run.start + offset},${y + offset}h${run.end - run.start}`;
            }
            else {
                // We can hline as we will only fill it
                path += `h${run.end - prevX}`;
            }
            // Draw the remainder of the rectangle clockwise
            path += `v1h${run.start - run.end}v-1`;
            // Remember current position for next segment
            prevX = run.start;
        }
        paths.push(path);
    }
    return paths.join("");
}
function svgDrawingCommandsFromQrCode(qr, quietZone) {
    return svgPaths(runLengthQr(qr), quietZone);
}
exports.svgDrawingCommandsFromQrCode = svgDrawingCommandsFromQrCode;
function QRCode(props) {
    const { value, bgColor, fgColor = "#000", size, rotated, quietZone = 1, } = props;
    const qrcode = typeof value === "string"
        ? qrcodegen_1.qrcodegen.QrCode.encodeText(value, qrcodegen_1.qrcodegen.QrCode.Ecc.LOW)
        : value;
    const runs = runLengthQr(qrcode);
    const extend = qrcode.size + 2 * quietZone;
    return ((0, jsx_runtime_1.jsxs)("svg", { width: size, height: size, viewBox: `0 0 ${extend} ${extend}`, transform: rotated ? `rotate(${rotated})` : undefined, children: [bgColor ? (0, jsx_runtime_1.jsx)("rect", { fill: bgColor, width: extend, height: extend }) : null, (0, jsx_runtime_1.jsx)("path", { fill: fgColor, d: svgPaths(runs, quietZone) })] }));
}
exports.QRCode = QRCode;
