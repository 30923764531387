"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.summarize = exports.summariesSeenSchema = exports.summariesSchema = exports.summarySeenSchema = exports.summarySchema = exports.diagnosisArray = exports.diagnosisLiterals = void 0;
const zod_1 = require("zod");
const infection_1 = require("./infection");
const diagnosisCategories = Object.keys(infection_1.infectionSchemaDiagnosisPlain.shape);
exports.diagnosisLiterals = diagnosisCategories.map((e) => zod_1.z.literal(e));
exports.diagnosisArray = zod_1.z.array(zod_1.z.union(exports.diagnosisLiterals));
exports.summarySchema = zod_1.z.object({
    id: zod_1.z.string(),
    name: zod_1.z.string(),
    town: zod_1.z.string(),
    diagnosis: exports.diagnosisArray,
    created: zod_1.z.number(),
    lastModified: zod_1.z.number(),
    // Not provided by the server, but the user can use it
    modified: zod_1.z.enum(["added", "deleted", "changed"]).optional(),
});
exports.summarySeenSchema = exports.summarySchema.extend({
    seen: zod_1.z.number().optional(),
});
exports.summariesSchema = zod_1.z.array(exports.summarySchema);
exports.summariesSeenSchema = zod_1.z.array(exports.summarySeenSchema);
function summarize(id, ir) {
    var _a, _b, _c, _d, _e, _f;
    const diagnosis = Array.from(Object.keys(ir.diagnosis));
    return {
        id,
        name: ir.patient.name,
        town: ir.patient.domicil.address,
        diagnosis,
        created: (_c = (_b = (_a = ir.history) === null || _a === void 0 ? void 0 : _a.changes) === null || _b === void 0 ? void 0 : _b[0].at) !== null && _c !== void 0 ? _c : 0,
        lastModified: (_f = (_e = (_d = ir.history) === null || _d === void 0 ? void 0 : _d.changes) === null || _e === void 0 ? void 0 : _e.slice(-1)[0].at) !== null && _f !== void 0 ? _f : 0,
    };
}
exports.summarize = summarize;
