import { Switch, Route, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useLanguage } from "./hooks/useLanguage";
import { ScrollToTop } from "./components/ScrollToTop";
import { Header } from "./components/Header";
import { Landing } from "./pages/Landing";
import { Footer } from "./components/Footer";
import { InfectionReport } from "./pages/InfectionReport";
import { useEffect } from "react";
import { initializeI18n } from "./i18n";
import { Statistics } from "./pages/Statistics";

initializeI18n({
  language: localStorage.getItem("language") ?? "en",
});

export function Page() {
  // eslint-disable-next-line
  const [language, setLanguage] = useLanguage();
  const { i18n, t } = useTranslation("", {});
  const title = t("title");
  const description = t("description");
  const faviconUrl = "/logo512.png";
  const previewImageUrl = "/logo512.png";

  useEffect(() => {
    /* Set HTML lang attribute */
    document.documentElement.setAttribute("lang", language);
    /* Set react-i18next language */
    i18n.changeLanguage(language);
  }, [language, i18n]);

  return (
    <>
      <Helmet>
        <title>
          {title}: {description}
        </title>
        <meta name="description" content={description} />
        <meta name="keywords" content={t("keywords")} />
        <link rel="icon" href={faviconUrl} />
        <link rel="apple-touch-icon" href={faviconUrl} />
        <meta name="msapplication-TileImage" content={faviconUrl} />
        <meta property="og:locale" content={language} />
        <meta property="og:site_name" content={`${title}: ${description}`} />
        <meta property="og:url" content={globalThis.location.pathname} />
        <meta property="og:title" content={title} />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={faviconUrl} />
        <meta property="og:image:url" content={previewImageUrl} />
        <meta property="og:image:secure_url" content={previewImageUrl} />
        <meta itemProp="name" content={title} />
        <meta itemProp="headline" content={`${title}: ${description}`} />
        <meta itemProp="image" content={faviconUrl} />
        <meta name="twitter:title" content={`${title}: ${description}`} />
        <meta name="twitter:card" content="summary_large_image" />
        <link rel="canonical" href={globalThis.location.pathname} />
        <link
          rel="alternate"
          hrefLang="de"
          href={`${globalThis.location.pathname}?language=de`}
        />
        <link
          rel="alternate"
          hrefLang="en"
          href={`${globalThis.location.pathname}?language=en`}
        />
        <link
          rel="preload"
          href="/Saira.subset.woff2"
          as="font"
          type="font/woff2"
        />
      </Helmet>

      <Header />
      <ScrollToTop />
      <Switch>
        <Route path={["/new/:recover", "/new"]}>
          <InfectionReport key="new" />
        </Route>
        <Route path="/report/:reportId">
          <InfectionReport />
        </Route>
        <Route path="/list">
          <Statistics />
        </Route>
        <Route path="/">
          <Landing />
        </Route>
        <Route path="*">
          <Redirect to="/" />
        </Route>
      </Switch>
      <Footer />
    </>
  );
}
