import { Suspense, useState } from "react";
import {
  ChakraProvider,
  Stack,
  extendTheme,
  ButtonProps,
} from "@chakra-ui/react";
import { BrowserRouter } from "react-router-dom";
import { WarningTwoIcon } from "@chakra-ui/icons";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { createWSClient, httpBatchLink, splitLink, wsLink } from "@trpc/client";
import { CenteredSpinner } from "./components/CenteredSpinner";
import { Page } from "./Page";
import Fonts from "./Fonts";
import { LoginProvider, peekLogin } from "./contexts/LoginContext";
import { trpc } from "./util/trpc";
import { apiUrl } from "./constants";

export const brand = {
  48: "#ecf7f2",
  49: "#d3ebdd",
  50: "#daf6d7",
  100: "#c2e1c7",
  200: "#b9dcba",
  300: "#b1d7af",
  400: "#a8d1a2",
  500: "#97c990",
  600: "#82be79",
  700: "#74b66a",
  800: "#539649",
  900: "#005000",
} as const;

export const strongBlue = "#2020c0";

export const theme = extendTheme({
  colors: { brand },
  components: {
    Alert: {
      // Toast
      baseStyle: {},
      sizes: {
        sm: {
          title: {
            fontSize: "1.5rem",
            lineHeight: "125%",
          },
          description: { fontSize: "1.5rem", lineHeight: "125%" },
          icon: { w: "14", h: "14" },
        },
      },
    },
    Text: {
      baseStyle: {
        fontSize: "1.5rem",
      },
    },
    Table: {
      baseStyle: {
        tr: { pl: 5 },
        td: {},
      },
      sizes: {
        sm: {
          th: { pl: 2, pr: 1 },
          td: { px: 2 },
        },
        md: {
          th: { pl: 2, pr: 1 },
          td: { px: 2 },
        },
      },
    },
    Button: {
      defaultProps: { variant: "outline", colorScheme: "brand" },
      baseStyle: {
        color: "brand.900",
        textColor: "brand.900",
        //variant: "outline",
        // Border width is being ignored
        borderWidth: "2px",
        border: "solid 2px",
        borderColor: "brand.900",
        boxShadow: "",
        _hover: {
          color: "brand.800",
          textColor: "brand.900",
          borderColor: "brand.800",
          backgroundColor: "gray.200",
        },
      },
    },
    FormLabel: {
      baseStyle: {
        fontSize: "1.5rem",
      },
    },
    Radio: {
      defaultProps: { size: "lg" },
    },
    Input: {
      defaultProps: {
        size: "lg",
        field: { textOverflow: "ellipsis ellipsis" },
      },
    },
    Checkbox: {
      defaultProps: { size: "lg" },
    },
    Link: {
      baseStyle: {
        color: strongBlue,
      },
    },
  },
});
// Was unable to get them styled in the above theme
export const textInputTheme = {
  bg: "brand.100",
  borderColor: "brand.800",
  color: "brand.900",
  fontSize: { base: "xl", md: "2xl" },
  focusBorderColor: "brand.900",
  _hover: {
    borderColor: "brand.700",
    borderWidth: "2px",
    bg: "brand.50",
  },
  _focusVisible: {
    borderColor: "brand.800",
    borderWidth: "2px",
    color: "black",
    bg: "white",
  },
} as const;
export const radioTheme = {
  color: "brand.900",
  borderColor: "brand.800",
} as const;
export const checkboxTheme = {
  color: "brand.900",
  borderColor: "brand.800",
} as const;
export const dangerButtonProps: ButtonProps = {
  border: "solid 2px",
  color: "red.700",
  textColor: "red.700",
  borderColor: "red.700",
  rightIcon: <WarningTwoIcon />,
  paddingY: 2,

  _hover: {
    color: "red.600",
    textColor: "red.600",
    borderColor: "red.600",
    backgroundColor: "red.100",
  },
};
export const boringButtonProps: ButtonProps = {
  border: "solid 2px",
  color: "gray.700",
  textColor: "gray.700",
  borderColor: "gray.700",
  paddingY: 2,

  _hover: {
    color: "gray.600",
    textColor: "gray.600",
    borderColor: "gray.600",
    backgroundColor: "gray.300",
  },
};

function exponentialBackoff(retry: number) {
  // Exponential backoff from 1 to 30 seconds
  const base = retry <= 0 ? 1000 : Math.min(2 ** retry * 1000, 30 * 1000);
  // Wiggle ±25% to reduce server load peaks
  const delay = Math.floor((0.5 * Math.random() + 0.75) * base);
  console.info(`Retry ${retry}: Reconnecting in ${delay / 1000}s`);
  return delay;
}

export function App() {
  const [queryClient] = useState(() => new QueryClient());
  const [wsClient] = useState(() =>
    createWSClient({
      // ws:// or wss://
      url: apiUrl.replace(/^http/, "ws"),
      retryDelayMs: exponentialBackoff,
    })
  );
  const [trpcClient] = useState(() =>
    trpc.createClient({
      links: [
        splitLink({
          condition: (op) => {
            return op.type === "subscription";
          },
          true: wsLink({
            client: wsClient,
          }),
          false: httpBatchLink({
            url: `${apiUrl}/rpc`,
            // optional
            headers() {
              const login = peekLogin();
              return {
                authorization: login
                  ? `bearer ${login.bearerToken}`
                  : undefined,
              };
            },
          }),
        }),
      ],
    })
  );
  return (
    <BrowserRouter>
      <ChakraProvider theme={theme}>
        <LoginProvider>
          <trpc.Provider client={trpcClient} queryClient={queryClient}>
            <QueryClientProvider client={queryClient}>
              <Fonts />
              <ReactQueryDevtools initialIsOpen={false} />
              <Suspense fallback={<CenteredSpinner />}>
                <Stack spacing={0} minHeight="100vh" width="100%">
                  <Page />
                </Stack>
              </Suspense>
            </QueryClientProvider>
          </trpc.Provider>
        </LoginProvider>
      </ChakraProvider>
    </BrowserRouter>
  );
}
