import { useContext } from "react";
import { DownloadIcon } from "@chakra-ui/icons";
import {
  Box,
  Link,
  TableContainer,
  Table,
  Tbody,
  Tr,
  Td,
  Th,
  Thead,
  Tooltip,
  useBreakpointValue,
  Text,
  Button,
  Spacer,
  Input,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { InfectionSchema, infectionSchema } from "irs-shared";
import { DateText } from "./DateText";
import { fullName } from "./History";
import { OuterBox } from "./OuterBox";
import { MaybeTooltip } from "./MaybeTooltip";
import { EditingContext } from "../contexts/EditingContext";
import { DataZorm } from "../util/dataZorm";
import { trpc } from "../util/trpc";
import { apiUrl } from "../constants";
import { CenteredSpinner } from "./CenteredSpinner";

export function LabReports(props: {
  dz: DataZorm<typeof infectionSchema>;
  setIr: (newIr: InfectionSchema) => void;
  reportId: string | undefined;
}) {
  const { dz, setIr, reportId } = props;
  const createLabReport = trpc.lab.create.useMutation({
    onSuccess: (data) => {
      const newAttachments = [...(dz.data.attachments ?? []), data];
      setIr({ ...dz.data, attachments: newAttachments });
    },
  });
  const { editing } = useContext(EditingContext);
  const { t } = useTranslation("");
  const narrow = useBreakpointValue({ base: true, sm: false });

  if (reportId === undefined && dz.data.attachments === undefined) {
    // New, or recover with no existing attachments
    return null;
  } else {
    // Data to display; no editing allowed when initially recovering, however
    return (
      <OuterBox
        preventEditing={reportId === undefined}
        name="attachments.title"
      >
        {dz.data.attachments ? (
          <TableContainer width="100%" marginBottom={3}>
            <Table size="md">
              <Thead>
                <Tr>
                  <Th>{t("attachments.name")}</Th>
                  <Th>{t("attachments.when")}</Th>
                  <Th>{t("attachments.who")}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {dz.data.attachments.map((v, i) => (
                  <Tr key={`${i}`}>
                    <Td>
                      <Tooltip label={t("attachments.download")}>
                        <Link
                          paddingY={1}
                          color="brand.900"
                          href={`${apiUrl}/lab/${v.document}`}
                          isExternal
                          _hover={{ backgroundColor: "brand.50" }}
                        >
                          {v.name}
                          <DownloadIcon
                            marginLeft={2}
                            width="1.5rem"
                            height="1.5rem"
                          />
                        </Link>
                      </Tooltip>
                      <Input
                        type="hidden"
                        name={dz.fields.attachments(i).name()}
                        value={v.name}
                      />
                      <Input
                        type="hidden"
                        name={dz.fields.attachments(i).document()}
                        value={v.document}
                      />
                    </Td>
                    <Td>
                      <DateText millis={v.at} />
                      <Input
                        type="hidden"
                        name={dz.fields.attachments(i).at()}
                        value={v.at}
                      />
                    </Td>
                    <Td>
                      <Tooltip label={narrow ? fullName(v.by) : v.by}>
                        {narrow ? v.by : fullName(v.by)}
                      </Tooltip>
                      <Input
                        type="hidden"
                        name={dz.fields.attachments(i).by()}
                        value={v.by}
                      />
                    </Td>
                  </Tr>
                ))}
                {createLabReport.isLoading ? (
                  <Tr key="loading">
                    <Td colSpan={3}>
                      <CenteredSpinner />
                    </Td>
                  </Tr>
                ) : undefined}
              </Tbody>
            </Table>
          </TableContainer>
        ) : null}
        {reportId ? (
          <MaybeTooltip name="attachments.create" width="100%" margin={3}>
            <Spacer />
            <Button
              border="solid 2px"
              backgroundColor="brand.300"
              isDisabled={!editing}
              onClick={() => createLabReport.mutate(reportId)}
              isLoading={createLabReport.isLoading}
            >
              <Text>{t("attachments.create")}</Text>
            </Button>
          </MaybeTooltip>
        ) : null}
        <Box height={2} />
      </OuterBox>
    );
  }
}
