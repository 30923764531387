import {
  FormControl,
  HStack,
  FormLabel,
  Input,
  InputProps,
  Text,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { HelpText } from "./HelpText";
import { textInputTheme } from "../App";
import { MaybeTooltip } from "./MaybeTooltip";
import { ErrorText } from "./ErrorText";
import { useContext } from "react";
import { EditingContext } from "../contexts/EditingContext";

/**
 * A labeled text input field with Zorm data/error support and tooltip/help options
 * @param props
 * @returns
 */
export function TextInput<T extends string>(
  props: {
    name: string;
    error?: z.ZodIssue;
    defaultValue?: T;
    hideLabel?: boolean;
  } & InputProps
) {
  const {
    name,
    error,
    defaultValue,
    hideLabel,
    colorScheme = "brand",
    paddingLeft,
    ...rest
  } = props;
  const { t } = useTranslation("");
  const { editing, editable } = useContext(EditingContext);

  return (
    <FormControl>
      <HStack>
        {hideLabel ? null : (
          <FormLabel
            minWidth="8em"
            marginTop={2}
            paddingLeft={paddingLeft}
            fontSize={{ base: "xl", md: "2xl" }}
          >
            <MaybeTooltip name={name}>{t(name)}</MaybeTooltip>
          </FormLabel>
        )}
        {editable ? (
          <Input
            isReadOnly={!editing}
            size={{ base: "sm", md: "md" }}
            type="text"
            name={name}
            defaultValue={defaultValue}
            {...textInputTheme}
            {...rest}
          />
        ) : (
          <Text
            paddingLeft={3}
            overflowX="scroll"
            color="brand.900"
            backgroundColor="brand.50"
            width="100%"
          >
            {defaultValue}
          </Text>
        )}
      </HStack>
      <HelpText name={name} colorScheme={colorScheme} />
      <ErrorText error={error} />
    </FormControl>
  );
}
